import { Outlet } from "react-router-dom";
import NavigationBar from "../nav";
import Footer from "../footer";
import { ScrollToTop } from "../../utils/utils";
import { FaArrowUp } from "react-icons/fa";
import { useEffect, useCallback, useState } from "react";
import { MdElectricBolt } from "react-icons/md";

const Layout = () => {
  const [clickEmojis, setClickEmojis] = useState([]);

  const createEmoji = useCallback((x, y, delay = 0, direction = 0) => {
    const spread = 150; // How far the emojis spread from the click point
    const angle = (direction * Math.PI) / 4; // Divide circle into 8 directions
    
    return {
      id: Date.now() + delay,
      x,
      y,
      delay,
      direction,
      screenHeight: window.innerHeight,
      screenWidth: window.innerWidth,
      // Calculate the target position based on direction
      targetX: x + Math.cos(angle) * spread,
      targetY: y + Math.sin(angle) * spread
    };
  }, []);

  const handleClick = useCallback((e) => {
    e.preventDefault();
    const numEmojis = 8; // Increased to 8 emojis
    
    const newEmojis = Array.from({ length: numEmojis }, (_, i) => 
      createEmoji(e.clientX, e.clientY, i * 30, i) // Reduced delay between emojis
    );

    setClickEmojis(prev => [...prev, ...newEmojis]);

    // Remove emojis after animation
    setTimeout(() => {
      const ids = newEmojis.map(emoji => emoji.id);
      setClickEmojis(prev => prev.filter(emoji => !ids.includes(emoji.id)));
    }, 1000); // Reduced cleanup time
  }, [createEmoji]);

  const handleMouseMove = useCallback((e) => {
    const circle = document.getElementById("circle");
    if (!circle) return;

    const getParents = (elem) => {
      const parents = [];
      for (; elem && elem !== document; elem = elem.parentNode) {
        parents.push(elem.tagName);
      }
      return parents;
    };

    const parents = getParents(e.target);
    const isInteractive = parents?.some(tag => 
      ["A", "BUTTON", "INPUT"].includes(tag)
    );

    if (isInteractive) {
      circle.classList.add("big");
    } else {
      circle.classList.remove("big");
    }

    // Use requestAnimationFrame for smoother animation
    requestAnimationFrame(() => {
      circle.style.left = `${e.clientX}px`;
      circle.style.top = `${e.clientY}px`;
    });
  }, []);

  useEffect(() => {
    // Debounce the mousemove event
    let timeoutId;
    const debouncedMouseMove = (e) => {
      if (timeoutId) {
        cancelAnimationFrame(timeoutId);
      }
      timeoutId = requestAnimationFrame(() => handleMouseMove(e));
    };

    document.addEventListener("mousemove", debouncedMouseMove);
    document.addEventListener("click", handleClick);

    // Cleanup
    return () => {
      document.removeEventListener("mousemove", debouncedMouseMove);
      document.removeEventListener("click", handleClick);
      if (timeoutId) {
        cancelAnimationFrame(timeoutId);
      }
    };
  }, [handleMouseMove, handleClick]);

  return (
    <div
      className='bg-slate-100 dark:bg-slate-900 relative tracking-wider leading-relaxed min-h-screen transition-colors duration-300'
      onClick={handleClick}
    >
      <div id='circle' className='circle'>
        <MdElectricBolt className='m-auto h-full text-amber-400 dark:text-amber-300' />
      </div>
      <div className="fixed inset-0 pointer-events-none z-[9999]">
        {clickEmojis.map(emoji => (
          <div
            key={emoji.id}
            className="absolute animate-volt-emoji"
            style={{
              left: `${emoji.x}px`,
              top: `${emoji.y}px`,
              transform: 'translate(-50%, -50%)',
              animationDelay: `${emoji.delay}ms`,
              '--target-x': `${emoji.targetX}px`,
              '--target-y': `${emoji.targetY}px`,
              '--start-x': `${emoji.x}px`,
              '--start-y': `${emoji.y}px`,
            }}
          >
            <MdElectricBolt className="text-3xl text-yellow-500 dark:text-yellow-400" />
          </div>
        ))}
      </div>
      <NavigationBar />
      <div className='grid grid-cols-12'>
        <div className='col-span-2'></div>
        <div className='col-span-12 lg:col-span-8 pt-32'>
          <Outlet />
        </div>
        <div className='col-span-2'></div>
      </div>
      <Footer />
      <BackToTop />
    </div>
  );
};

const BackToTop = () => {
  return (
    <div
      className='fixed bottom-12 right-5 rounded-full bg-gradient-to-br from-amber-500 to-amber-600 dark:from-amber-400 dark:to-amber-500 p-4 text-lg text-white shadow-2xl transition-all duration-300 hover:scale-110 hover:shadow-amber-300/20 dark:hover:shadow-amber-400/20 z-50 cursor-pointer'
      onClick={ScrollToTop}
    >
      <FaArrowUp className="transition-transform group-hover:animate-bounce" />
    </div>
  );
};

export default Layout;
