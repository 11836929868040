import { Link } from "react-router-dom";
import { IoShieldCheckmarkSharp } from "react-icons/io5";
import { ScrollToTop } from "../../utils/utils";
import { GiElectric, GiPowerGenerator } from "react-icons/gi";
import { MdElectricalServices } from "react-icons/md";
import { FaArrowRight } from "react-icons/fa";
import SEO from "../../components/SEO";

const PRODUCTS_DATA = [
  {
    title: "Panel Boards",
    head: "High Voltage",
    link: "/products/hv",
    range: ["12", "KV", "13", "KV", "1250", "A"],
    desc: [
      "Load Break Switch",
      "Indoor VCB",
      "Outdoor VCB ",
      "Ring Main Unit",
      "Compact Substation",
    ],
    color: "bg-amber-500",
    icon: GiElectric,
    bgGradient: "bg-amber-50 dark:bg-amber-900"
  },
  {
    title: "Panel Boards",
    head: "Medium Voltage",
    link: "/products/mv",
    range: ["630", "A", "6300", "A", "1100", "V"],
    desc: [
      "Air circuit Breaker",
      "PLC-SCADA Panel",
      "Motor Control Centre Panel",
      "Sub Switch Board",
      "Automatic Power factor control ",
      "Main voltage Panel",
    ],
    color: "bg-blue-500",
    icon: GiPowerGenerator,
    bgGradient: "bg-blue-50 dark:bg-blue-900"
  },
  {
    title: "Panel Boards",
    head: "Low Voltage",
    link: "/products/lv",
    range: ["63", "A", "400", "A", "660", "V"],
    desc: [
      "Lighting Distribution Panel",
      "MCB Distribution Panel",
      "ACB panel",
      "EB Meter Box Busbar",
      "LTCT Meter Box",
    ],
    color: "bg-emerald-500",
    icon: MdElectricalServices,
    bgGradient: "bg-emerald-50 dark:bg-emerald-900"
  },
];

function Products() {
  return (
    <>
      <SEO 
        title="Products | KRICO - Industrial Electrical Control Panels"
        description="Explore KRICO's comprehensive range of electrical control panels including High Voltage, Medium Voltage, and Low Voltage solutions. Industry-leading quality and innovation in panel manufacturing."
        keywords="electrical panels, control panels, HV panels, MV panels, LV panels, industrial electrical solutions, panel boards, switchgear"
        path="/products"
      />
      <section className='bg-white dark:bg-gray-900 py-12 px-4 sm:px-6 lg:px-8'>
        <div className='max-w-7xl mx-auto'>
          <div className='text-center space-y-4 mb-16'>
            <h2 className='text-4xl font-extrabold tracking-tight text-gray-900 dark:text-white sm:text-5xl'>
              Designed For Every Need
            </h2>
            <p className='max-w-2xl mx-auto text-xl text-gray-500 dark:text-gray-400'>
              We focus on markets where technology, innovation, and capital can
              unlock long-term value and sustainable solutions.
            </p>
          </div>

          <div className='grid grid-cols-1 gap-8 lg:grid-cols-3 lg:gap-6'>
            {PRODUCTS_DATA.map((product, index) => (
              <Product key={index} product={product} />
            ))}
          </div>
        </div>
      </section>
    </>
  );
}

function Product({ product }) {
  return (
    <div
      className={`relative flex flex-col p-6 h-full ${product.bgGradient} rounded-2xl shadow-lg hover:shadow-2xl transition-all duration-300 hover:-translate-y-1 border border-gray-100 dark:border-gray-800`}
      data-aos='fade-up'
      data-aos-delay={200}
      data-aos-duration='1000'
    >
      <div className={`absolute top-0 left-0 w-full h-2 ${product.color} rounded-t-2xl opacity-90 dark:opacity-80`} />
      
      <div className='relative mb-8'>
        <div className='flex items-center justify-between'>
          <h3 className='text-2xl font-bold text-gray-900 dark:text-white'>{product.head}</h3>
          <div className={`w-14 h-14 ${product.color} bg-opacity-10 dark:bg-opacity-20 rounded-full flex items-center justify-center`}>
            <product.icon size={28} className={`text-${product.color.split('-')[1]}-600 dark:text-${product.color.split('-')[1]}-400`} />
          </div>
        </div>
        <p className='mt-2 text-sm text-gray-500 dark:text-gray-400'>{product.title}</p>
      </div>

      <div className='bg-white/50 dark:bg-gray-800/30 rounded-xl p-4 mb-8'>
        <div className='flex items-center justify-center space-x-1 font-mono'>
          <span className={`text-xl font-bold ${
            product.head === "High Voltage" ? "bg-red-100 text-red-800 dark:bg-red-900/30 dark:text-red-200" :
            product.head === "Medium Voltage" ? "bg-blue-100 text-blue-800 dark:bg-blue-900/30 dark:text-blue-200" :
            "bg-green-100 text-green-800 dark:bg-green-900/30 dark:text-green-200"
          } px-3 py-1 rounded-lg`}>{product.range[0]}</span>
          <span className='text-gray-600 dark:text-gray-300'>-</span>
          <span className={`text-xl font-bold ${
            product.head === "High Voltage" ? "bg-red-100 text-red-800 dark:bg-red-900/30 dark:text-red-200" :
            product.head === "Medium Voltage" ? "bg-blue-100 text-blue-800 dark:bg-blue-900/30 dark:text-blue-200" :
            "bg-green-100 text-green-800 dark:bg-green-900/30 dark:text-green-200"
          } px-3 py-1 rounded-lg`}>{product.range[2]}</span>
          <span className='text-gray-600 dark:text-gray-300'>{product.range[3]}</span>
          <span className='text-gray-600 dark:text-gray-300 mx-2'>/</span>
          <span className={`text-xl font-bold ${
            product.head === "High Voltage" ? "bg-red-100 text-red-800 dark:bg-red-900/30 dark:text-red-200" :
            product.head === "Medium Voltage" ? "bg-blue-100 text-blue-800 dark:bg-blue-900/30 dark:text-blue-200" :
            "bg-green-100 text-green-800 dark:bg-green-900/30 dark:text-green-200"
          } px-3 py-1 rounded-lg`}>{product.range[4]}</span>
          <span className='text-gray-600 dark:text-gray-300'>{product.range[5]}</span>
        </div>
      </div>

      <div className='flex-1 space-y-3 mb-8'>
        {product.desc.map((desc, index) => (
          <div 
            key={index}
            className='flex items-start space-x-3 p-2 rounded-lg hover:bg-white/80 dark:hover:bg-gray-800/50 transition-colors duration-200'
          >
            <IoShieldCheckmarkSharp className='w-5 h-5 text-gray-500 dark:text-gray-400 flex-shrink-0 mt-0.5' />
            <span className='text-gray-700 dark:text-gray-200 text-sm'>{desc}</span>
          </div>
        ))}
      </div>

      <Link
        to={product.link}
        onClick={ScrollToTop}
        className={`group relative inline-flex w-full items-center justify-center overflow-hidden rounded-xl p-4 font-medium transition-all duration-300
          ${product.color} hover:scale-[1.02] active:scale-[0.98]`}
      >
        <span className="absolute inset-0 bg-black/30 transition-all duration-300 group-hover:bg-black/40" />
        <div className="relative flex items-center justify-center gap-2 text-white">
          <span className="text-sm font-semibold tracking-wide">View Details</span>
          <FaArrowRight 
            className="h-4 w-4 transition-transform duration-300 group-hover:translate-x-1"
            aria-hidden="true"
          />
        </div>
      </Link>
    </div>
  );
}

export default Products;
