import { Helmet } from 'react-helmet-async';

const SEO = ({ 
  title = "KRICO | Best electrical control panel manufacturer and suppliers in India",
  description = "Krico Pannel is one of the best industrial electrical control panel manufacturer and suppliers in India offering all types of HV, MV and LV panels with assured quality.",
  keywords = "electrical control panel, panel manufacturer, industrial panels, HV panels, MV panels, LV panels, India",
  author = "Krico",
  ogType = "website",
  ogImage = "/iconc.png",
  path = ""
}) => {
  const siteUrl = process.env.REACT_APP_SITE_URL || "https://www.krico.com";
  const fullUrl = `${siteUrl}${path}`;
  
  return (
    <Helmet>
      {/* Basic Meta Tags */}
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <meta name="author" content={author} />
      
      {/* Open Graph Meta Tags */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content={ogType} />
      <meta property="og:url" content={fullUrl} />
      <meta property="og:image" content={`${siteUrl}${ogImage}`} />
      <meta property="og:site_name" content="Krico" />
      
      {/* Twitter Card Meta Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={`${siteUrl}${ogImage}`} />
      
      {/* Additional SEO Meta Tags */}
      <meta name="robots" content="index, follow" />
      <meta name="googlebot" content="index, follow" />
      <meta name="revisit-after" content="7 days" />
      <link rel="canonical" href={fullUrl} />
      
      {/* Schema.org Markup */}
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "Organization",
          "name": "Krico",
          "description": description,
          "url": siteUrl,
          "logo": `${siteUrl}/iconc.png`,
          "contactPoint": {
            "@type": "ContactPoint",
            "telephone": "+91-97509 10064",
            "contactType": "customer service"
          }
        })}
      </script>
    </Helmet>
  );
};

export default SEO;
