import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IoSearch, IoClose } from "react-icons/io5";
import { FaBox, FaIndustry, FaProjectDiagram } from "react-icons/fa";
import { motion, AnimatePresence } from "framer-motion";
import { HVolt, MVolt, LVolt } from "../pages/productlist/products";
import { Projects } from "../pages/productlist/projects";
import Infrastructure from "../pages/infrastructure/index";

// Category and source references
const CATEGORIES = {
  PRODUCT: 'product',
  INFRASTRUCTURE: 'infrastructure',
  PROJECT: 'project'
};

const SOURCES = {
  HIGH_VOLTAGE: 'High Voltage Panel',
  MEDIUM_VOLTAGE: 'Medium Voltage Panel',
  LOW_VOLTAGE: 'Low Voltage Panel',
  INFRASTRUCTURE: 'Infrastructure',
  INDUSTRIAL_PROJECTS: 'Industrial Projects'
};

const CATEGORY_TITLES = {
  [CATEGORIES.PRODUCT]: 'Products',
  [CATEGORIES.INFRASTRUCTURE]: 'Infrastructure',
  [CATEGORIES.PROJECT]: 'Projects'
};

const SearchBar = ({ onClose }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const searchRef = useRef(null);
  const navigate = useNavigate();

  // Get infrastructure data from the component's static data
  const infrastructureData = {
    items: [
      { title: "Corporate Office, Designing Area" },
      { title: "MIG & ARC Welding Unit" },
      { title: "Laser Cutting Machine" },
      { title: "CNC PRESS BRAKE MACHINE – 80 T / 4000 mm" },
      { title: "CNC SHEARING MACHINE – 4 x 3200 mm" },
      { title: "POWDER COATING UNIT" },
      { title: "8 Tank Process" },
      { title: "Busbar Bending Machine" }
    ]
  };

  // Search data with categories and sources
  const searchData = [
    // Products from High Voltage
    ...HVolt.map(item => ({
      id: `p${item.id}`,
      title: item.title,
      url: `/product/hv/${item.id}`,
      category: CATEGORIES.PRODUCT,
      source: SOURCES.HIGH_VOLTAGE
    })),
    
    // Products from Medium Voltage
    ...MVolt.map(item => ({
      id: `p${item.id}`,
      title: item.title,
      url: `/product/mv/${item.id}`,
      category: CATEGORIES.PRODUCT,
      source: SOURCES.MEDIUM_VOLTAGE
    })),
    
    // Products from Low Voltage
    ...LVolt.map(item => ({
      id: `p${item.id}`,
      title: item.title,
      url: `/product/lv/${item.id}`,
      category: CATEGORIES.PRODUCT,
      source: SOURCES.LOW_VOLTAGE
    })),
    
    // Infrastructure items
    ...infrastructureData.items.map((item, index) => ({
      id: `i${index + 1}`,
      title: item.title,
      url: `/infrastructure#${item.title.toLowerCase().replace(/[^a-z0-9]+/g, '-').replace(/-+/g, '-').replace(/^-|-$/g, '')}`,
      category: CATEGORIES.INFRASTRUCTURE,
      source: SOURCES.INFRASTRUCTURE
    })),
    
    // Projects
    ...Projects.items.map(item => ({
      id: `pr${item.id}`,
      title: item.title,
      url: `/projects/${item.id}`,
      category: CATEGORIES.PROJECT,
      source: SOURCES.INDUSTRIAL_PROJECTS
    }))
  ];

  const handleSearch = (query) => {
    setSearchQuery(query);
    if (query.trim() === "") {
      setSearchResults([]);
      setIsOpen(false);
      return;
    }

    const results = searchData.filter((item) =>
      item.title.toLowerCase().includes(query.toLowerCase())
    );

    // Group results by category
    const groupedResults = results.reduce((acc, item) => {
      const category = item.category;
      if (!acc[category]) {
        acc[category] = [];
      }
      acc[category].push(item);
      return acc;
    }, {});

    setSearchResults(Object.entries(groupedResults));
    setIsOpen(true);
  };

  const handleResultClick = (url) => {
    setSearchQuery("");
    setSearchResults([]);
    setIsOpen(false);
    if (onClose) onClose();
    navigate(url);
  };

  const getCategoryIcon = (category) => {
    switch (category) {
      case CATEGORIES.PRODUCT:
        return <FaBox className="w-4 h-4" />;
      case CATEGORIES.INFRASTRUCTURE:
        return <FaIndustry className="w-4 h-4" />;
      case CATEGORIES.PROJECT:
        return <FaProjectDiagram className="w-4 h-4" />;
      default:
        return null;
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div ref={searchRef} className="relative w-full max-w-md">
      <div className="relative">
        <input
          type="text"
          value={searchQuery}
          onChange={(e) => handleSearch(e.target.value)}
          placeholder="Search products, infrastructure, projects..."
          className="w-full pl-10 pr-4 py-2 text-sm bg-slate-100 dark:bg-slate-800 rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-400 dark:focus:ring-yellow-500 text-slate-900 dark:text-slate-100"
        />
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <IoSearch className="h-5 w-5 text-slate-400" />
        </div>
        {searchQuery && (
          <button
            onClick={() => {
              setSearchQuery("");
              setSearchResults([]);
              setIsOpen(false);
            }}
            className="absolute inset-y-0 right-0 pr-3 flex items-center"
          >
            <IoClose className="h-5 w-5 text-slate-400 hover:text-slate-600 dark:hover:text-slate-300" />
          </button>
        )}
      </div>

      <AnimatePresence>
        {isOpen && searchResults.length > 0 && (
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            className="absolute z-50 w-full mt-2 bg-white dark:bg-slate-800 rounded-lg shadow-lg border border-slate-200 dark:border-slate-700 max-h-96 overflow-y-auto"
          >
            {searchResults.map(([category, items]) => (
              <div key={category}>
                <div className="sticky top-0 px-4 py-2 bg-slate-50 dark:bg-slate-800/90 backdrop-blur-sm border-b border-slate-200 dark:border-slate-700">
                  <span className="text-xs font-medium text-slate-500 dark:text-slate-400 uppercase">
                    {CATEGORY_TITLES[category]}
                  </span>
                </div>
                {items.map((result) => (
                  <div
                    key={result.id}
                    onClick={() => handleResultClick(result.url)}
                    className="px-4 py-3 hover:bg-yellow-50 dark:hover:bg-yellow-900/20 cursor-pointer border-b border-slate-100 dark:border-slate-700 last:border-0"
                  >
                    <div className="flex items-center space-x-3">
                      <span className="flex-shrink-0 text-yellow-500 dark:text-yellow-400">
                        {getCategoryIcon(result.category)}
                      </span>
                      <div className="flex-1 min-w-0">
                        <p className="text-sm font-medium text-slate-900 dark:text-slate-100 truncate">
                          {result.title}
                        </p>
                        <p className="text-xs text-slate-500 dark:text-slate-400 mt-0.5">
                          {result.source}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default SearchBar;
