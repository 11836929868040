import { Swiper, SwiperSlide } from "swiper/react";

import { Autoplay } from "swiper/modules";

import { Projects as projects } from "../pages/productlist/projects";

import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import "swiper/css";
import { ScrollToTop } from "../../utils/utils";

function Projects() {
  const projs = projects.items
    .slice(0, 6)
    .map((p, i) => ({
      img: p.img[0],
      id: p.id,
      title: p.title,
      category: p.category,
    }));

  return (
    <section className="relative py-20 bg-white dark:bg-slate-900">
      <div className="container mx-auto px-4">
        {/* Header Section */}
        <div className="text-center mb-16" data-aos="fade-up">
          <div className="inline-block mb-4">
            <span className="bg-yellow-50 dark:bg-yellow-400/10 text-yellow-800 dark:text-yellow-200 text-sm font-medium px-4 py-1.5 rounded-full border border-yellow-200/50 dark:border-yellow-400/20">
              Our Work
            </span>
          </div>
          <h2 className="text-4xl font-bold text-slate-900 dark:text-yellow-100 mb-4">
            Featured Projects
          </h2>
          <p className="text-slate-600 dark:text-slate-300 max-w-2xl mx-auto">
            Explore our portfolio of successful electrical engineering projects across various industries
          </p>
        </div>

        {/* Projects Slider */}
        <div data-aos="fade-up" data-aos-delay="100">
          <Swiper
            modules={[Autoplay]}
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
              pauseOnMouseEnter: true,
            }}
            breakpoints={{
              640: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 30,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 40,
              },
              1536: {
                slidesPerView: 4,
                spaceBetween: 50,
              },
            }}
            loop={true}
            className="pb-12"
          >
            {projs.map((proj, id) => (
              <SwiperSlide key={id}>
                <Project proj={proj} id={id} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>

        {/* View All Link */}
        <div className="text-center mt-8" data-aos="fade-up" data-aos-delay="200">
          <Link 
            to="/projects" 
            onClick={ScrollToTop}
            className="inline-flex items-center gap-2 bg-yellow-50 dark:bg-yellow-400/10 hover:bg-yellow-100 dark:hover:bg-yellow-400/20 text-yellow-800 dark:text-yellow-200 px-6 py-3 rounded-full border border-yellow-200/50 dark:border-yellow-400/20 transition-all duration-300 hover:scale-105"
          >
            View All Projects
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd" />
            </svg>
          </Link>
        </div>
      </div>
    </section>
  );
}

function Project({ proj }) {
  return (
    <Link
      to={`projects/${proj.id}`}
      className="group relative block overflow-hidden rounded-2xl bg-white dark:bg-slate-800 shadow-lg transition-all duration-300 hover:shadow-2xl hover:scale-105"
      onClick={ScrollToTop}
    >
      {/* Image Container */}
      <div className="aspect-square overflow-hidden">
        <img
          loading="lazy"
          src={proj.img}
          alt={proj.title}
          className="h-full w-full object-cover transition-transform duration-300 group-hover:scale-110"
        />
      </div>
      
      {/* Content Overlay */}
      <div className="absolute inset-0 bg-gradient-to-t from-black/80 via-black/50 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300">
        <div className="absolute bottom-0 left-0 right-0 p-6">
          <h3 className="text-xl font-semibold text-white mb-2">
            {proj.title}
          </h3>
          <p className="text-yellow-200 text-sm">
            {proj.category}
          </p>
        </div>
      </div>
    </Link>
  );
}

export default Projects;
