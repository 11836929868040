import { useState, useEffect } from "react";
import { MdOutlineLocationOn } from "react-icons/md";
import { FiPhone, FiMail, FiSend, FiCheck } from "react-icons/fi";
import { Address } from "../../footer";
import AOS from "aos";
import "aos/dist/aos.css";
import SEO from "../../../components/SEO";

const Contact = () => {
  const [src, setSrc] = useState(
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4351.808391807043!2d49.65635796524076!3d26.990845573863723!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e35a1b7ec81aa69%3A0xfd3de2103a858495!2s101%20Business%20Tower!5e0!3m2!1sen!2sin!4v1731306676194!5m2!1sen!2sin"
  );

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);

  const copyContactItems = (text, setText) => {
    navigator.clipboard.writeText(text);
    setText("Copied!");
    setTimeout(() => {
      setText(text);
    }, 2000);
  };

  const setAddressText = (text, setText) => {
    setText("Loading Map...");
    setTimeout(() => {
      setText(text);
    }, 2000);
  };

  document.title = `Krico | Contact`;

  return (
    <>
      <SEO 
        title="Contact Us | KRICO - Get in Touch for Electrical Solutions"
        description="Contact KRICO for expert electrical control panel solutions. Reach out to our team for inquiries, support, or collaboration opportunities. We're here to help with your industrial electrical needs."
        keywords="contact Krico, electrical panel support, industrial solutions contact, electrical engineering support, panel manufacturer contact"
        path="/contact"
      />
      <div className="py-20 bg-gradient-to-b from-white to-slate-50 dark:from-slate-900 dark:to-slate-800">
        <div className="container mx-auto px-4 max-w-7xl">
          {/* Header */}
          <div className="mb-24 relative" data-aos="fade-up">
            <div className="absolute -top-10 left-0 w-24 h-24 bg-yellow-100 dark:bg-yellow-500/20 rounded-full blur-3xl"></div>
            <div className="mb-4 text-center">
              <span className="bg-yellow-50 dark:bg-yellow-500/30 text-yellow-600 dark:text-yellow-300 text-sm font-medium px-6 py-2 rounded-full border border-yellow-200/50 dark:border-yellow-500/30 shadow-sm">
                Get in Touch
              </span>
            </div>
            <h1 className="text-6xl font-bold text-slate-900 dark:text-yellow-300 mb-6 tracking-tight text-center">
              Contact Us
            </h1>
            <p className="text-2xl font-semibold text-slate-600 dark:text-slate-300 max-w-2xl leading-relaxed mx-auto text-center">
              We'd love to hear from you
            </p>
          </div>

          {/* Contact Form Section */}
          <div 
            className="mb-16"
            data-aos="fade-up"
          >
            <div className="p-8">
              <ContactForm />
            </div>
          </div>

          {/* Map and Contact Info Grid */}
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
            {/* Map Section */}
            <div 
              className="bg-white dark:bg-slate-800 rounded-2xl shadow-xl overflow-hidden backdrop-blur-lg border border-slate-200 dark:border-slate-700 h-[800px] transform transition-all hover:shadow-2xl"
              data-aos="fade-right"
            >
              <iframe
                src={src}
                title="google map"
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                className="w-full h-full"
              ></iframe>
            </div>

            {/* Contact Information Section */}
            <div 
              className="bg-white dark:bg-slate-800 rounded-2xl shadow-xl overflow-hidden backdrop-blur-lg border border-slate-200 dark:border-slate-700 p-8 transform transition-all hover:shadow-2xl h-[800px]"
              data-aos="fade-left"
            >
              <div className="space-y-8">
                {/* Office Section */}
                <div className="transform transition-all hover:translate-x-2">
                  <h2 className="text-2xl font-bold text-slate-900 dark:text-yellow-300 mb-4 flex items-center">
                    <MdOutlineLocationOn className="w-6 h-6 mr-2 text-yellow-500 dark:text-yellow-400" />
                    Head Office
                  </h2>
                  <div className="text-slate-700 dark:text-slate-300">
                    <Address
                      addres={{
                        clk: (text, setText) => {
                          setSrc(
                            "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4351.808391807043!2d49.65635796524076!3d26.990845573863723!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e35a1b7ec81aa69%3A0xfd3de2103a858495!2s101%20Business%20Tower!5e0!3m2!1sen!2sin!4v1731306676194!5m2!1sen!2sin"
                          );
                          setAddressText(text, setText);
                        },
                        text: [
                          "KRICO PANNEL Global Private Limited,",
                          "#176 – Mettukadu, Chinnamedu,",
                          "Thindal, Erode – 638 012,",
                          "Tamilnadu, India.",
                        ],
                      }}
                      phone={{
                        clk: copyContactItems,
                        text: ["+91 97509 10064"],
                      }}
                      email={{
                        clk: copyContactItems,
                        text: ["sales@kricoelectric.com"],
                      }}
                      className="text-slate-700 dark:text-slate-300"
                    />
                  </div>
                </div>

                {/* Factory Section */}
                <div className="transform transition-all hover:translate-x-2">
                  <h2 className="text-2xl font-bold text-slate-900 dark:text-yellow-300 mb-4 flex items-center">
                    <FiMail className="w-6 h-6 mr-2 text-yellow-500 dark:text-yellow-400" />
                    Factory
                  </h2>
                  <div className="text-slate-700 dark:text-slate-300">
                    <Address
                      addres={{
                        clk: (text, setText) => {
                          setSrc(
                            "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4351.808391807043!2d49.65635796524076!3d26.990845573863723!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e35a1b7ec81aa69%3A0xfd3de2103a858495!2s101%20Business%20Tower!5e0!3m2!1sen!2sin!4v1731306676194!5m2!1sen!2sin"
                          );
                          setAddressText(text, setText);
                        },
                        text: [
                          "KRICO PANNEL Global Private Limited,",
                          "D.No.96/4, Thottipalayam Main Street,",
                          "Nasiyanur Post, Erode - 638 107",
                        ],
                      }}
                      phone={{
                        clk: copyContactItems,
                        text: ["+91 96889 10064"],
                      }}
                      email={{
                        clk: copyContactItems,
                        text: [
                          "kricoglobalpurchase21@gmail.com",
                        ],
                      }}
                      className="text-slate-700 dark:text-slate-300"
                    />
                  </div>
                </div>

                {/* Global Office Section */}
                <div className="transform transition-all hover:translate-x-2">
                  <h2 className="text-2xl font-bold text-slate-900 dark:text-yellow-300 mb-4 flex items-center">
                    <FiPhone className="w-6 h-6 mr-2 text-yellow-500 dark:text-yellow-400" />
                    Global Office
                  </h2>
                  <div className="text-slate-700 dark:text-slate-300">
                    <Address
                      addres={{
                        clk: (text, setText) => {
                          setSrc(
                            "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4351.808391807043!2d49.65635796524076!3d26.990845573863723!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e35a1b7ec81aa69%3A0xfd3de2103a858495!2s101%20Business%20Tower!5e0!3m2!1sen!2sin!4v1731306676194!5m2!1sen!2sin"
                          );
                          setAddressText(text, setText);
                        },
                        text: [
                          "KRICO PANNEL Global Private Limited,",
                          "101 Business Tower, Sharjah",
                          "United Arab Emirates",
                        ],
                      }}
                      phone={{
                        clk: copyContactItems,
                        text: ["+966 55 892 8453"],
                      }}
                      email={{
                        clk: copyContactItems,
                        text: ["admin@kricoelectric.com"],
                      }}
                      className="text-slate-700 dark:text-slate-300"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    mobile: '',
    email: '',
    message: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [touched, setTouched] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleBlur = (e) => {
    const { name } = e.target;
    setTouched(prev => ({
      ...prev,
      [name]: true
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setTouched({
      name: true,
      mobile: true,
      email: true,
      message: true
    });

    // Simulate API call
    await new Promise(resolve => setTimeout(resolve, 1500));
    
    setSubmitSuccess(true);
    setIsSubmitting(false);
    
    // Reset form after 2 seconds
    setTimeout(() => {
      setSubmitSuccess(false);
      setFormData({
        name: '',
        mobile: '',
        email: '',
        message: ''
      });
      setTouched({});
    }, 2000);
  };

  const inputClasses = (fieldName) => `
    w-full px-4 py-3 rounded-lg
    border ${touched[fieldName] && !formData[fieldName] ? 'border-red-300 dark:border-red-500' : 'border-slate-300 dark:border-slate-600'}
    bg-white dark:bg-slate-800
    text-slate-900 dark:text-slate-100
    placeholder-slate-400 dark:placeholder-slate-500
    focus:outline-none focus:ring-2 focus:ring-yellow-500
    transition-all duration-300
    transform hover:shadow-md focus:shadow-lg
    ${isSubmitting ? 'opacity-50 cursor-not-allowed' : ''}
  `;

  const labelClasses = `
    block text-sm font-medium
    text-slate-700 dark:text-slate-200
    mb-2 transition-colors
  `;

  return (
    <form onSubmit={handleSubmit} className="space-y-6" data-aos="fade-up">
      <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
        {/* Name Input */}
        <div className="relative group">
          <label htmlFor="name" className={labelClasses}>
            Name <span className="text-yellow-500">*</span>
          </label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            onBlur={handleBlur}
            className={inputClasses('name')}
            placeholder="John Doe"
            required
            disabled={isSubmitting}
          />
          {touched.name && !formData.name && (
            <p className="mt-1 text-sm text-red-500 dark:text-red-400">Name is required</p>
          )}
        </div>

        {/* Mobile Input */}
        <div className="relative group">
          <label htmlFor="mobile" className={labelClasses}>
            Mobile <span className="text-yellow-500">*</span>
          </label>
          <input
            type="tel"
            id="mobile"
            name="mobile"
            value={formData.mobile}
            onChange={handleChange}
            onBlur={handleBlur}
            className={inputClasses('mobile')}
            placeholder="+1 (555) 000-0000"
            required
            disabled={isSubmitting}
          />
          {touched.mobile && !formData.mobile && (
            <p className="mt-1 text-sm text-red-500 dark:text-red-400">Mobile number is required</p>
          )}
        </div>
      </div>

      {/* Email Input */}
      <div className="relative group">
        <label htmlFor="email" className={labelClasses}>
          Email <span className="text-yellow-500">*</span>
        </label>
        <input
          type="email"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          onBlur={handleBlur}
          className={inputClasses('email')}
          placeholder="john.doe@example.com"
          required
          disabled={isSubmitting}
        />
        {touched.email && !formData.email && (
          <p className="mt-1 text-sm text-red-500 dark:text-red-400">Email is required</p>
        )}
      </div>

      {/* Message Input */}
      <div className="relative group">
        <label htmlFor="message" className={labelClasses}>
          Message <span className="text-yellow-500">*</span>
        </label>
        <textarea
          id="message"
          name="message"
          value={formData.message}
          onChange={handleChange}
          onBlur={handleBlur}
          rows="4"
          className={`${inputClasses('message')} resize-none`}
          placeholder="How can we help you?"
          required
          disabled={isSubmitting}
        ></textarea>
        {touched.message && !formData.message && (
          <p className="mt-1 text-sm text-red-500 dark:text-red-400">Message is required</p>
        )}
      </div>

      {/* Submit Button */}
      <div className="relative">
        <button
          type="submit"
          disabled={isSubmitting}
          className={`
            w-full flex items-center justify-center
            px-6 py-3.5
            text-base font-medium
            rounded-lg text-white
            transition-all duration-300
            transform hover:scale-[1.02] hover:shadow-lg
            focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500
            disabled:opacity-50 disabled:cursor-not-allowed
            ${submitSuccess
              ? 'bg-green-500 hover:bg-green-600'
              : 'bg-yellow-500 hover:bg-yellow-600 dark:bg-yellow-500 dark:hover:bg-yellow-600'
            }
          `}
        >
          <div className="relative flex items-center">
            {isSubmitting ? (
              <>
                <svg className="animate-spin h-5 w-5 mr-2" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" fill="none" />
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                </svg>
                Sending...
              </>
            ) : submitSuccess ? (
              <>
                <FiCheck className="w-5 h-5 mr-2" />
                Message Sent!
              </>
            ) : (
              <>
                <FiSend className="w-5 h-5 mr-2" />
                Send Message
              </>
            )}
          </div>
        </button>
      </div>
    </form>
  );
};

export default Contact;
