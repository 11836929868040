import { useParams, Link } from "react-router-dom";
import { Projects } from "../productlist/projects";
import { motion, AnimatePresence } from "framer-motion";
import { FaIndustry } from "react-icons/fa";
import { useState } from "react";
import SEO from "../../../components/SEO";

const ProjectDescription = () => {
  const { pid } = useParams();
  const [selectedImage, setSelectedImage] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Verify Projects data exists
  if (!Projects || !Projects.items) {
    return (
      <section className="py-20 bg-gradient-to-b from-white to-slate-50 dark:from-slate-900 dark:to-slate-800">
        <div className="container mx-auto px-4 max-w-7xl text-center">
          <h1 className="text-4xl font-bold text-slate-900 dark:text-yellow-100">
            Error loading projects
          </h1>
          <Link 
            to="/"
            className="inline-block mt-6 px-6 py-2 bg-yellow-50 text-yellow-800 dark:bg-yellow-400/10 dark:text-yellow-200 rounded-full border border-yellow-200/50 dark:border-yellow-400/20 hover:bg-yellow-100 dark:hover:bg-yellow-400/20 transition-colors"
          >
            Return Home
          </Link>
        </div>
      </section>
    );
  }

  const project = Projects.items.find((p) => p.id.toString() === pid);

  // Handle project not found
  if (!project) {
    return (
      <section className="py-20 bg-gradient-to-b from-white to-slate-50 dark:from-slate-900 dark:to-slate-800">
        <div className="container mx-auto px-4 max-w-7xl text-center">
          <h1 className="text-4xl font-bold text-slate-900 dark:text-yellow-100">
            Project not found
          </h1>
          <Link 
            to="/projects"
            className="inline-block mt-6 px-6 py-2 bg-yellow-50 text-yellow-800 dark:bg-yellow-400/10 dark:text-yellow-200 rounded-full border border-yellow-200/50 dark:border-yellow-400/20 hover:bg-yellow-100 dark:hover:bg-yellow-400/20 transition-colors"
          >
            View All Projects
          </Link>
        </div>
      </section>
    );
  }

  // Prepare meta description and keywords with safety checks
  const metaDescription = project.description && project.description.length > 0
    ? project.description.substring(0, 155) + "..."
    : `${project.title} - Industrial electrical control panel project by Krico`;

  const keywords = `${project.title}, ${project.category || 'industrial project'}, electrical control panel project, case study, Krico projects`;

  const fadeInUp = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.6,
        ease: "easeOut"
      }
    }
  };

  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
        delayChildren: 0.3
      }
    }
  };

  const item = {
    hidden: { opacity: 0, y: 20 },
    show: { 
      opacity: 1, 
      y: 0,
      transition: {
        type: "spring",
        bounce: 0.4,
        duration: 0.8
      }
    }
  };

  const imageVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    show: { 
      opacity: 1, 
      scale: 1,
      transition: {
        type: "spring",
        stiffness: 260,
        damping: 20
      }
    },
    hover: { 
      scale: 1.05,
      transition: {
        type: "spring",
        stiffness: 400,
        damping: 10
      }
    },
    tap: { scale: 0.95 }
  };

  const overlayVariants = {
    hidden: { opacity: 0 },
    visible: { 
      opacity: 1,
      transition: {
        duration: 0.2
      }
    },
    exit: {
      opacity: 0,
      transition: {
        duration: 0.2
      }
    }
  };

  const modalVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 25
      }
    },
    exit: {
      opacity: 0,
      scale: 0.8,
      transition: {
        duration: 0.2
      }
    }
  };

  return (
    <section className="py-20 bg-gradient-to-b from-white to-slate-50 dark:from-slate-900 dark:to-slate-800">
      <SEO 
        title={`Krico | ${project.title}`}
        description={metaDescription}
        keywords={keywords}
        ogType="article"
        ogImage={project.img?.[0]}
        path={`/project/${pid}`}
      />
      <motion.div 
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        className="container mx-auto px-4 max-w-7xl"
      >
        <div className="mb-24 relative" data-aos="fade-up">
          <motion.div 
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            className="absolute -top-10 left-0 w-24 h-24 bg-yellow-100 dark:bg-yellow-400/10 rounded-full blur-3xl"
          />
          
          <motion.div 
            variants={fadeInUp}
            initial="hidden"
            animate="visible"
            className="mb-4 text-center"
          >
            <span className="bg-yellow-50 dark:bg-yellow-400/10 text-yellow-800 dark:text-yellow-200 text-sm font-medium px-6 py-2 rounded-full border border-yellow-200/50 dark:border-yellow-400/20 shadow-sm">
              {project.category}
            </span>
          </motion.div>

          <motion.h1 
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.3 }}
            className="text-6xl font-bold text-slate-900 dark:text-yellow-100 mb-6 tracking-tight text-center"
          >
            {project.title}
          </motion.h1>

          <motion.div 
            variants={container}
            initial="hidden"
            animate="show"
            className="mt-12"
          >
            {/* Project Details Card */}
            <motion.div 
              variants={item}
              className="mb-12"
            >
              <div className="grid grid-cols-1 gap-8">
                {/* Image Gallery */}
                {project.img && project.img.length > 0 && (
                  <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6">
                    <AnimatePresence>
                      {project.img.map((image, index) => (
                        <motion.div
                          key={index}
                          variants={imageVariants}
                          initial="hidden"
                          animate="show"
                          whileHover="hover"
                          whileTap="tap"
                          onClick={() => setSelectedImage(image)}
                          className="relative aspect-square rounded-2xl overflow-hidden shadow-xl cursor-pointer transform-gpu"
                          data-aos="zoom-in"
                          data-aos-delay={index * 100}
                        >
                          <motion.img
                            src={image}
                            alt={`${project.title} - Image ${index + 1}`}
                            className="w-full h-full object-cover"
                            loading="lazy"
                          />
                        </motion.div>
                      ))}
                    </AnimatePresence>
                  </div>
                )}
                {/* Project Information */}
                <div className="text-center">
                  <motion.div 
                    variants={item} 
                    className="inline-flex items-center space-x-2 bg-yellow-50 dark:bg-yellow-400/10 px-6 py-3 rounded-full"
                  >
                    <FaIndustry className="text-yellow-600 dark:text-yellow-300" />
                    <span className="text-yellow-800 dark:text-yellow-200 font-medium">
                      {project.category}
                    </span>
                  </motion.div>

                  {project.description && project.description.length > 0 && (
                    <motion.div 
                      variants={item}
                      className="mt-8 prose prose-lg dark:prose-invert mx-auto"
                    >
                      {project.description.map((paragraph, index) => (
                        <p key={index}>{paragraph}</p>
                      ))}
                    </motion.div>
                  )}
                </div>
              </div>
            </motion.div>
          </motion.div>
        </div>
      </motion.div>

      {/* Image Modal */}
      <AnimatePresence>
        {selectedImage && (
          <motion.div
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={overlayVariants}
            className="fixed inset-0 bg-black/80 z-50 flex items-center justify-center p-4 md:p-8"
            onClick={() => setSelectedImage(null)}
          >
            <motion.div
              variants={modalVariants}
              className="relative max-w-7xl w-full bg-white dark:bg-slate-800 rounded-3xl overflow-hidden shadow-2xl"
              onClick={(e) => e.stopPropagation()}
            >
              <img
                src={selectedImage}
                alt="Project detail"
                className="w-full h-auto"
                loading="lazy"
              />
              <button
                onClick={() => setSelectedImage(null)}
                className="absolute top-4 right-4 text-white bg-black/50 hover:bg-black/70 rounded-full p-2"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </section>
  );
};

export default ProjectDescription;
