import Clients from "../clients";
import Products from "../products";
import Projects from "../projects";
import BanerV2 from "../hero/banner.v2";
import CPDESC from "../cpdescription";
import SEO from "../../components/SEO";

function Home() {
  return (
    <>
      <SEO 
        title="KRICO | Best electrical control panel manufacturer and suppliers in India"
        description="Krico Pannel manufactures high-quality industrial electrical control panels including HV, MV, and LV panels. Leading supplier in India with ISO certification and expert solutions."
        keywords="electrical control panel manufacturer, industrial panel supplier, HV panels, MV panels, LV panels, electrical solutions, India, ISO certified"
        path="/"
      />
      <BanerV2 />
      {/* <WhyKrico /> */}
      <Products />
      <Clients />
      <CPDESC />
      <Projects />
    </>
  );
}

export default Home;
