import { Link } from "react-router-dom";
import icon from "../../img/icon.png";
import { useEffect, useRef, useState } from "react";
import { ScrollToTop } from "../../utils/utils";
import { IoMenu } from "react-icons/io5";
import { FaAngleDown } from "react-icons/fa";
import { FiSun, FiMoon } from "react-icons/fi";
import SearchBar from "./SearchBar";

// Dark mode functions
const initializeDarkMode = () => {
  if (localStorage.theme === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
    document.documentElement.classList.add('dark');
    return true;
  } else {
    document.documentElement.classList.remove('dark');
    return false;
  }
};

const toggleDarkMode = (isDark) => {
  if (isDark) {
    document.documentElement.classList.add('dark');
    localStorage.theme = 'dark';
  } else {
    document.documentElement.classList.remove('dark');
    localStorage.theme = 'light';
  }
};

const menuItems = [
  { text: "Home", link: "/" },
  { text: "About", link: "/about" },
  {
    text: "Products",
    link: "#",
    submenu: [
      {
        text: "High Voltage Panel",
        link: "/products/hv",
        submenu: [
          { text: "Indoor VCB", link: "/product/hv/1" },
          { text: "Outdoor VCB", link: "/product/hv/2" },
          { text: "Load Break Switch", link: "/product/hv/3" },
          { text: "Ring Main Unit", link: "/product/hv/4" },
          { text: "Compact Substation", link: "/product/hv/5" },
        ],
      },
      {
        text: "Medium Voltage Panel",
        link: "/products/mv",
        submenu: [
          { text: "Air Circuit Breaker Panel", link: "/product/mv/6" },
          { text: "Main Voltage Panel", link: "/product/mv/7" },
          { text: "Sub Switch Board", link: "/product/mv/8" },
          { text: "Automatic Power Factor Control", link: "/product/mv/9" },
          { text: "Motor Control Center Panel", link: "/product/mv/10" },
          { text: "PLC – SCADA Panel", link: "11" },
        ],
      },
      {
        text: "Low Voltage Panel",
        link: "/products/lv",
        submenu: [
          { text: "LTCT Meter Box", link: "/product/lv/12" },
          { text: "EB Meter Box Busbar", link: "/product/lv/13" },
          { text: "VTPN Distribution Boards", link: "/product/lv/14" },
          { text: "MCB Distribution Boards", link: "/product/lv/15" },
          { text: "Lighting Distribution Panel", link: "/product/lv/16" },
        ],
      },
    ],
  },
  {
    text: "Service",
    link: "#",
    submenu: [
      { text: "Electrical Consultancy", link: "/service/consultancy" },
      {
        text: "Electrical Engineering Contractors",
        link: "/service/contractors",
      },
      { text: "Maintenance", link: "/service/maintenance" },
    ],
  },
  { text: "Infrastructure", link: "/infrastructure" },
  { text: "Projects", link: "/projects" },
  { text: "Contact", link: "/contact" },
];

const displayMobileMenu = () => {
  document.getElementById("mobile-menu").classList.toggle("hidden");
  const isSmall = document
    .getElementById("mobile-menu")
    .classList.contains("hidden");

  const classList = ["h-16", "sm:h-20", "border-b-2"];
  const iconClassList = ["scale-75"];
  if (window.scrollY > 64) {
    if (isSmall) {
      classList.map((cls) => document.getElementById("nav").classList.add(cls));
      iconClassList.map((cls) =>
        document.getElementById("icon").classList.add(cls)
      );
    } else {
      classList.map((cls) =>
        document.getElementById("nav").classList.remove(cls)
      );
      iconClassList.map((cls) =>
        document.getElementById("icon").classList.remove(cls)
      );
    }
  }
};

const NavBar = ({ isOpen, setIsOpen }) => {
  const [isDark, setIsDark] = useState(initializeDarkMode());
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <nav
      id="nav"
      className={`fixed top-0 left-0 right-0 z-[100] transition-all duration-300 ${
        isScrolled
          ? "h-20 sm:h-24 bg-white/80 dark:bg-slate-900/80 backdrop-blur-lg shadow-lg border-b border-slate-200 dark:border-slate-700"
          : "h-24 sm:h-28 bg-white dark:bg-slate-900 border-b border-slate-200 dark:border-slate-700"
      }`}
    >
      <div className="container mx-auto px-4 h-full">
        <div className="flex items-center justify-between h-full">
          {/* Logo */}
          <div className="flex items-center">
            <Link
              to="/"
              className="flex items-center"
              onClick={() => setIsOpen(false)}
            >
              <img
                src={icon}
                alt="logo"
                className={`transition-all duration-300 ${
                  isScrolled ? "h-12 sm:h-14" : "h-14 sm:h-16"
                }`}
              />
            </Link>
          </div>

          {/* Desktop Menu */}
          <div className="hidden lg:flex items-center flex-1 justify-end">
            <div className="ml-8 flex-1 max-w-md">
              <SearchBar />
            </div>
            <div className="ml-8">
              <DestopView isScrolled={isScrolled} />
            </div>
            {/* Desktop Dark Mode Toggle */}
            <button
              onClick={() => {
                setIsDark(!isDark);
                toggleDarkMode(!isDark);
              }}
              className="ml-8 p-2 rounded-lg hover:bg-yellow-50 dark:hover:bg-yellow-400/10 text-slate-600 dark:text-yellow-300 transition-colors"
              aria-label="Toggle Dark Mode"
            >
              {isDark ? (
                <FiSun className="w-5 h-5" />
              ) : (
                <FiMoon className="w-5 h-5" />
              )}
            </button>
          </div>

          {/* Mobile Menu Button */}
          <div className="lg:hidden flex items-center">
            <button
              onClick={() => {
                setIsDark(!isDark);
                toggleDarkMode(!isDark);
              }}
              className="mr-4 p-2 rounded-lg hover:bg-yellow-50 dark:hover:bg-yellow-400/10 text-slate-600 dark:text-yellow-300 transition-colors"
              aria-label="Toggle Dark Mode"
            >
              {isDark ? (
                <FiSun className="w-5 h-5" />
              ) : (
                <FiMoon className="w-5 h-5" />
              )}
            </button>
            
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="p-2 rounded-lg hover:bg-yellow-50 dark:hover:bg-yellow-400/10 text-slate-600 dark:text-yellow-300 transition-colors"
              aria-label="Toggle Menu"
            >
              <IoMenu className="w-6 h-6" />
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
};

const DestopView = ({ isScrolled }) => {
  return (
    <div className="flex items-center space-x-1">
      {menuItems.map((item, index) => (
        <div key={index} className="relative group">
          {item.submenu ? (
            <div className="flex items-center">
              <Link
                to={item.submenu[0].link}
                className="px-4 py-2 rounded-lg text-slate-600 hover:text-slate-900 dark:text-slate-300 dark:hover:text-yellow-300 hover:bg-yellow-50 dark:hover:bg-yellow-400/10 transition-colors font-medium flex items-center"
              >
                {item.text}
                <FaAngleDown className="inline-block ml-1" />
              </Link>
              <div className="absolute top-full left-0 pt-2 w-64 opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-200">
                <div className="py-2 bg-white dark:bg-slate-800 rounded-lg shadow-xl border border-slate-100 dark:border-slate-700">
                  {item.submenu.map((subItem, subIndex) => (
                    <div key={subIndex} className="relative group/sub">
                      {subItem.submenu ? (
                        <>
                          <Link
                            to={subItem.link}
                            className="flex items-center justify-between w-full px-4 py-2 text-slate-600 hover:text-slate-900 dark:text-slate-300 dark:hover:text-yellow-300 hover:bg-yellow-50 dark:hover:bg-yellow-400/10 transition-colors font-medium"
                          >
                            {subItem.text}
                            <FaAngleDown className="inline-block ml-1 transform -rotate-90" />
                          </Link>
                          <div className="absolute top-0 left-full pl-2 w-64 opacity-0 invisible group-hover/sub:opacity-100 group-hover/sub:visible transition-all duration-200">
                            <div className="py-2 bg-white dark:bg-slate-800 rounded-lg shadow-xl border border-slate-100 dark:border-slate-700">
                              {subItem.submenu.map((subSubItem, subSubIndex) => (
                                <Link
                                  key={subSubIndex}
                                  to={subSubItem.link}
                                  className="block px-4 py-2 text-slate-600 hover:text-slate-900 dark:text-slate-300 dark:hover:text-yellow-300 hover:bg-yellow-50 dark:hover:bg-yellow-400/10 transition-colors"
                                >
                                  {subSubItem.text}
                                </Link>
                              ))}
                            </div>
                          </div>
                        </>
                      ) : (
                        <Link
                          to={subItem.link}
                          className="block px-4 py-2 text-slate-600 hover:text-slate-900 dark:text-slate-300 dark:hover:text-yellow-300 hover:bg-yellow-50 dark:hover:bg-yellow-400/10 transition-colors"
                        >
                          {subItem.text}
                        </Link>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ) : (
            <Link
              to={item.link}
              className="px-4 py-2 rounded-lg text-slate-600 hover:text-slate-900 dark:text-slate-300 dark:hover:text-yellow-300 hover:bg-yellow-50 dark:hover:bg-yellow-400/10 transition-colors font-medium"
            >
              {item.text}
            </Link>
          )}
        </div>
      ))}
    </div>
  );
};

const MobileMenuItems = ({ items, level = 0 }) => {
  const [openMenus, setOpenMenus] = useState({});

  const toggleMenu = (index) => {
    setOpenMenus(prev => ({
      ...prev,
      [index]: !prev[index]
    }));
  };

  return (
    <div className={`space-y-1 ${level > 0 ? 'ml-4' : ''}`}>
      {items.map((item, index) => (
        <div key={index}>
          {item.submenu ? (
            <div className="relative">
              <button 
                onClick={() => toggleMenu(index)}
                className="w-full text-left px-4 py-2 text-slate-600 hover:text-slate-900 dark:text-slate-300 dark:hover:text-yellow-300 hover:bg-yellow-50 dark:hover:bg-yellow-400/10 transition-colors flex items-center justify-between font-medium"
              >
                {item.text}
                <FaAngleDown className={`inline-block ml-1 transform transition-transform duration-200 ${openMenus[index] ? 'rotate-180' : ''}`} />
              </button>
              <div className={`mt-1 transition-all duration-200 ${openMenus[index] ? 'block' : 'hidden'}`}>
                <MobileMenuItems items={item.submenu} level={level + 1} />
              </div>
            </div>
          ) : (
            <Link
              to={item.link}
              className="block px-4 py-2 text-slate-600 hover:text-slate-900 dark:text-slate-300 dark:hover:text-yellow-300 hover:bg-yellow-50 dark:hover:bg-yellow-400/10 transition-colors font-medium"
            >
              {item.text}
            </Link>
          )}
        </div>
      ))}
    </div>
  );
};

const NavigationBar = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <NavBar isOpen={isOpen} setIsOpen={setIsOpen} />
      {/* Mobile menu */}
      <div
        className={`fixed inset-x-0 top-16 sm:top-20 bottom-0 bg-white dark:bg-slate-900 border-b border-slate-200 dark:border-slate-700 transform transition-transform duration-300 lg:hidden ${
          isOpen ? 'translate-y-0' : '-translate-y-full'
        }`}
      >
        <div className="container mx-auto px-4 py-4">
          <div className="mb-4">
            <SearchBar onClose={() => setIsOpen(false)} />
          </div>
          <MobileMenuItems items={menuItems} />
        </div>
      </div>
    </>
  );
};

export default NavigationBar;
