import { BsLightningChargeFill } from 'react-icons/bs';
import { MdSecurity, MdOutlineMonitor } from 'react-icons/md';
import { AiOutlineControl } from 'react-icons/ai';

function CPDESC() {
  const features = [
    {
      icon: <BsLightningChargeFill className="w-8 h-8 text-amber-500 dark:text-amber-400 group-hover:text-amber-600 dark:group-hover:text-amber-300" />,
      title: "Power Distribution",
      description: "Efficiently distributes and manages electrical power through advanced circuit systems"
    },
    {
      icon: <MdOutlineMonitor className="w-8 h-8 text-amber-500 dark:text-amber-400 group-hover:text-amber-600 dark:group-hover:text-amber-300" />,
      title: "Real-time Monitoring",
      description: "Continuous monitoring and diagnostics to prevent issues before they occur"
    },
    {
      icon: <MdSecurity className="w-8 h-8 text-amber-500 dark:text-amber-400 group-hover:text-amber-600 dark:group-hover:text-amber-300" />,
      title: "Safety Systems",
      description: "Advanced protection mechanisms ensuring secure operation of all electrical components"
    },
    {
      icon: <AiOutlineControl className="w-8 h-8 text-amber-500 dark:text-amber-400 group-hover:text-amber-600 dark:group-hover:text-amber-300" />,
      title: "Smart Controls",
      description: "Intuitive interface for efficient management of all electrical systems"
    }
  ];

  return (
    <section className="py-24 bg-gradient-to-b from-slate-50 to-white dark:from-slate-900 dark:to-black" data-aos="fade-up">
      <div className="container mx-auto px-4">
        {/* Main Content */}
        <div className="flex flex-col lg:flex-row items-center gap-16">
          {/* Left Column - Description */}
          <div className="lg:w-1/2">
            <div className="max-w-xl">
              <div className="inline-block mb-6">
                <span className="bg-gradient-to-r from-amber-100 to-yellow-100 dark:from-amber-500/20 dark:to-yellow-500/20 text-amber-800 dark:text-amber-200 text-sm font-medium px-4 py-1.5 rounded-full border border-amber-200/50 dark:border-amber-400/20">
                  Control Systems
                </span>
              </div>
              <h2 className="text-4xl font-bold bg-gradient-to-r from-slate-900 via-slate-800 to-amber-800 dark:from-amber-200 dark:via-amber-100 dark:to-yellow-200 bg-clip-text text-transparent mb-6">
                What is a Control Panel?
              </h2>
              <div className="prose prose-lg dark:prose-invert">
                <p className="text-slate-600 dark:text-slate-300 mb-6 leading-relaxed">
                  A control panel is the central nervous system of electrical power distribution, 
                  housing sophisticated components that work in harmony to manage and protect 
                  electrical systems.
                </p>
                <p className="text-slate-600 dark:text-slate-300 leading-relaxed">
                  These advanced units integrate circuit breakers, relays, and meters, 
                  providing comprehensive control and monitoring capabilities for industrial 
                  and commercial applications.
                </p>
              </div>
            </div>
          </div>

          {/* Right Column - Features */}
          <div className="lg:w-1/2">
            <div className="clay bg-gradient-to-br from-white via-slate-50 to-amber-50/30 dark:from-slate-800 dark:via-slate-800/50 dark:to-black p-8 rounded-2xl border-2 border-amber-200/50 dark:border-amber-700/30 shadow-xl shadow-amber-100/20 dark:shadow-amber-900/10">
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
                {features.map((feature, index) => (
                  <div
                    key={index}
                    className="group"
                    data-aos="fade-up"
                    data-aos-delay={100 * index}
                  >
                    <div className="flex flex-col items-start">
                      <div className="mb-4 transform transition-transform duration-300 group-hover:scale-110">
                        {feature.icon}
                      </div>
                      <h3 className="text-lg font-semibold text-slate-900 dark:text-amber-100 mb-2 group-hover:text-amber-700 dark:group-hover:text-amber-300 transition-colors">
                        {feature.title}
                      </h3>
                      <p className="text-sm text-slate-600 dark:text-slate-400 group-hover:text-slate-900 dark:group-hover:text-slate-300 transition-colors">
                        {feature.description}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* Bottom Note */}
        <div className="mt-16 text-center">
          <div className="inline-block clay bg-gradient-to-r from-amber-400/90 via-amber-300/90 to-yellow-300/90 dark:from-amber-400/20 dark:via-amber-300/20 dark:to-yellow-300/20 px-6 py-3 rounded-full border border-amber-500/50 dark:border-amber-400/30 shadow-lg shadow-amber-200/20 dark:shadow-amber-900/10">
            <p className="text-sm font-medium text-slate-900 dark:text-amber-200">
              Enhancing electrical system efficiency and safety
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CPDESC;
