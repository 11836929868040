import { useParams, Link } from "react-router-dom";
import { motion } from "framer-motion";
import { Breadcrumb } from "flowbite-react";
import { HiHome } from "react-icons/hi";
import { useState, useEffect } from "react";
import { MdOutlineElectricBolt } from "react-icons/md";
import TypeWriterEffect from "react-typewriter-effect";
import { FaQuoteLeft } from "react-icons/fa";
import SEO from "../../../components/SEO";

const services = [
  {
    id: "consultancy",
    icon: "https://ionvidle.sirv.com/krico/service/ELECTRICAL%20CONSULTANCY%20SERIVCE.jpg",
    title: "Electrical Consultancy",
    description: [
      "KRICO PANNEL GLOBAL PRIVATE LIMITED is a company comprising of highly experienced engineers in the field of Electrical Design, Estimation, Tendering, Project Management and Co-ordination. Company provide consultancy in the field of Electrical Design, Estimation, Tendering, Project Management and Co-ordination.",
      "The firm has been designing all types of Electrical and networking system, and specializing in designing systems for many diverse and specialized applications such as Hotels, Shopping Malls, Corporate offices, all type of industrials and Hospitals Sectors etc. Our endeavor has always been to design systems which are innovative yet economical. A special emphasis is also given on designing energy efficient systems using the latest technology.",
    ],
    quote: {
      show: true,
      item: [
        {
          title: "",
          desc: [""],
          points: [
            "Detailed analysis of client’s needs, project requirements.",
            "Study of site conditions and drawings.",
            "Coordination with architect to freeze preliminary design, provision of space requirement etc. for Electrical System.",
            "Provide assistance to client in evaluating the best alternative technically and economically to meet needs for present and future.",
            "To estimate the Load requirements for selection of Sub Station Equipments and DG Sets.",
            "Selection of suitable / economical Electrical Distribution System.",
            "Selection of Equipment.",
            "Estimation of space required for Electrical System such as HV / Main LT Panel Room, floor Elect, Panel Rooms, Cable routings etc.",
          ],
        },
      ],
    },
  },
  {
    id: "contractors",
    icon: "https://ionvidle.sirv.com/krico/service/ELECTRICAL%20CONTRACTOR.jpg",
    title: "Electrical Engineering Contractors",
    description: [
      "KRICO PANNEL GLOBAL PRIVATE LIMITED is a Electrical Contractor Service Company. We are proud of the reputation we have built for being a reliable and trustworthy electrical contractor, offering quality with affordability. With an extensive range of projects covering the Retail, commercial and industrial sectors, we boast a wealth of experience that we are able to apply to projects regardless of size or end use.",
    ],
    quote: {
      show: true,
      item: [
        {
          title: "A Skilled and Certified Workforce",
          desc: [
            "Our team consists of experienced electricians, who are trained to the latest industry standards, guaranteeing a quality service.",
            "We are an CEIG registered and approved contractor, and are Trustmark and Part EA certified contractor for HV / MV Installation work up to 33 KV Level.",
          ],
          points: [],
        },
        {
          title: "",
          desc: [
            "Recognized as one of leading electrical contractors, RKE Infra Group delivers high quality construction and maintenance outcomes for heavy industrial projects and power generation sectors.",
          ],
          points: [
            "CEIG Licensing Work (Electrical Drawing and Completion Report Certification)",
            "11, 22, 33 KV Sub Station Work",
            "High Voltage Distribution Work",
            "Transformer Erection up to 5 MVA",
            "Bus Duct Erection work",
            "LT Power Distribution work",
            "UG Cable Erection up to 630 Sq.mm",
            "Lighting and Control Wiring Work",
            "Energy Monitoring",
            "Internal Low Voltage Distribution work",
            "Earthing System & Lightning System",
            "All Types Tray Erection Work",
          ],
        },
      ],
    },
  },
  {
    id: "maintenance",
    icon: "https://ionvidle.sirv.com/krico/service/ELECTRICAL%20MAINTANANCE.jpg",
    title: "Maintenance",
    description: [
      "We provide comprehensive and non-comprehensive annual maintenance contract solutions, Electrical Maintenance services and Electrical Management services of Electrical Installations over a period of time (Annually, Half yearly, Quarterly, Monthly basis) to corporate companies, IT/BT sectors, Medical facilities, Multi-storied building, , Shopping malls, Multiplex etc.",
      "We are doing all kind of AMC in Electricals. Backed by a team of electrical professionals, we are offering a broad assortment of Industrial Electrical AMC Service for our customers. Offered services are executed under the direction of inspectors of quality. These are rendered according to the client’s demands and within the promised period of time.",
    ],
    quote: {
      show: false,
      item: [
        {
          title: "",
          desc: [],
          points: [],
        },
      ],
    },
  },
];

const fadeInUp = {
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0 },
  transition: { duration: 0.6 }
};

const staggerContainer = {
  animate: {
    transition: {
      staggerChildren: 0.1
    }
  }
};

const Service = () => {
  let { selectedservice } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [service, setService] = useState(null);
  const [relatedServices, setRelatedServices] = useState([]);

  // Generate SEO data based on service type
  const getSeoData = (service) => {
    if (!service) return {
      title: "Services | KRICO - Industrial Electrical Solutions",
      description: "Explore KRICO's comprehensive electrical services including consultancy, contracting, and maintenance for industrial and commercial sectors.",
      keywords: "electrical services, industrial solutions, electrical engineering",
      path: "/services"
    };

    // Service-specific SEO data
    const seoByService = {
      "consultancy": {
        title: "Electrical Consultancy Services | KRICO",
        description: "Expert electrical consultancy services for design, estimation, and project management. Specialized solutions for hotels, malls, corporate offices, and industrial sectors.",
        keywords: "electrical consultancy, electrical design, project management, industrial electrical consulting",
        serviceType: "ConsultingService"
      },
      "contractors": {
        title: "Electrical Engineering Contractors | KRICO",
        description: "Professional electrical contracting services for retail, commercial, and industrial sectors. CEIG registered contractor for HV/MV installations up to 33KV.",
        keywords: "electrical contractors, HV installation, MV installation, industrial electrical work",
        serviceType: "ContractorService"
      },
      "maintenance": {
        title: "Electrical Maintenance Services | KRICO",
        description: "Comprehensive electrical maintenance and AMC solutions for corporate, IT/BT, medical facilities, and commercial buildings. Expert electrical system management.",
        keywords: "electrical maintenance, AMC services, preventive maintenance, electrical system management",
        serviceType: "MaintenanceService"
      }
    };

    const serviceSeo = seoByService[service.id];
    
    // Create structured data for the service
    const structuredData = {
      "@context": "https://schema.org",
      "@type": serviceSeo.serviceType,
      "name": service.title,
      "description": service.description[0],
      "provider": {
        "@type": "Organization",
        "name": "KRICO PANNEL GLOBAL PRIVATE LIMITED",
        "description": "Leading provider of industrial electrical solutions"
      },
      "areaServed": {
        "@type": "Country",
        "name": "India"
      },
      "serviceType": service.title
    };

    // Add service-specific structured data
    if (service.quote && service.quote.show && service.quote.item[0].points.length > 0) {
      structuredData.hasOfferCatalog = {
        "@type": "OfferCatalog",
        "name": `${service.title} Features`,
        "itemListElement": service.quote.item[0].points.map(point => ({
          "@type": "Offer",
          "itemOffered": {
            "@type": "Service",
            "name": point
          }
        }))
      };
    }

    return {
      title: serviceSeo.title,
      description: serviceSeo.description,
      keywords: serviceSeo.keywords,
      path: `/services/${service.id}`,
      ogType: "website",
      ogTitle: `${service.title} - Professional Electrical Solutions`,
      ogDescription: serviceSeo.description,
      ogImage: service.icon,
      structuredData
    };
  };

  useEffect(() => {
    try {
      const foundService = services.find((s) => s.id === selectedservice);
      if (!foundService) {
        throw new Error("Service not found");
      }
      setService(foundService);
      
      const related = services
        .filter((s) => s.id !== selectedservice)
        .slice(0, 2);
      setRelatedServices(related);
      
      document.title = `Krico | ${foundService.title} Service`;
      // Scroll to top when service changes
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }, [selectedservice]);

  const seoData = getSeoData(service);

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen text-slate-900 dark:text-slate-100">
        <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-yellow-600 dark:border-yellow-400"></div>
      </div>
    );
  }

  if (error || !service) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen">
        <h2 className="text-2xl font-bold text-red-600 dark:text-red-400 mb-4">
          {error || "Service not found"}
        </h2>
        <Link
          to="/"
          className="px-6 py-2 bg-yellow-50 text-yellow-800 dark:bg-yellow-400/10 dark:text-yellow-200 rounded-full border border-yellow-200/50 dark:border-yellow-400/20 hover:bg-yellow-100 dark:hover:bg-yellow-400/20 transition-colors"
        >
          Return Home
        </Link>
      </div>
    );
  }

  return (
    <>
      <SEO 
        title={seoData.title}
        description={seoData.description}
        keywords={seoData.keywords}
        path={seoData.path}
        ogType={seoData.ogType}
        ogTitle={seoData.ogTitle}
        ogDescription={seoData.ogDescription}
        ogImage={seoData.ogImage}
        structuredData={seoData.structuredData}
      />
      <section className="py-20 bg-gradient-to-b from-white to-slate-50 dark:from-slate-900 dark:to-slate-800">
        <div className="container mx-auto px-4 max-w-7xl">
          {/* Header */}
          <motion.div 
            className="mb-24 relative" 
            initial={{ opacity: 0, y: -30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            <div className="absolute -top-10 left-0 w-24 h-24 bg-yellow-100 dark:bg-yellow-400/10 rounded-full blur-3xl"></div>
            <div className="mb-4 text-center">
              <span className="bg-yellow-50 dark:bg-yellow-400/10 text-yellow-800 dark:text-yellow-200 text-sm font-medium px-6 py-2 rounded-full border border-yellow-200/50 dark:border-yellow-400/20 shadow-sm">
                Our Services
              </span>
            </div>
            <motion.h1 
              className="text-6xl font-bold text-slate-900 dark:text-yellow-100 mb-6 tracking-tight text-center"
              initial={{ scale: 0.9, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              {service.title}
            </motion.h1>
          </motion.div>

          {/* Main Content */}
          <motion.div 
            className="grid grid-cols-1 lg:grid-cols-2 gap-12 mb-24"
            variants={staggerContainer}
            initial="initial"
            animate="animate"
          >
            <motion.div
              initial={{ opacity: 0, x: -50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.6, delay: 0.3 }}
              whileHover={{ scale: 1.02 }}
              className="bg-white dark:bg-slate-800/50 rounded-3xl shadow-xl overflow-hidden transform backdrop-blur-lg border border-slate-100 dark:border-slate-700 p-8"
            >
              <div className="space-y-8 text-slate-700 dark:text-slate-300">
                {service.description.map((description, index) => (
                  <TypeWriterEffect
                    key={index}
                    startDelay={index * 100}
                    typeSpeed={1}
                    hideCursorAfterText
                    cursorColor="currentColor"
                    text={description}
                    className="text-lg leading-relaxed"
                  />
                ))}
              </div>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, x: 50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.6, delay: 0.4 }}
              whileHover={{ scale: 1.02 }}
              className="bg-white dark:bg-slate-800/50 rounded-3xl shadow-xl overflow-hidden transform backdrop-blur-lg border border-slate-100 dark:border-slate-700"
            >
              <img
                src={service.icon}
                alt={service.title}
                className="w-full h-full object-cover"
                loading="lazy"
              />
            </motion.div>
          </motion.div>

          {/* Service Details */}
          {service.quote.show && (
            <motion.div 
              className="mb-24"
              initial={{ opacity: 0, y: 30 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.5 }}
            >
              <div className="absolute -top-10 right-0 w-32 h-32 bg-yellow-100 dark:bg-yellow-400/10 rounded-full blur-3xl"></div>
              <div className="mb-4 text-center">
                <span className="bg-yellow-50 dark:bg-yellow-400/10 text-yellow-800 dark:text-yellow-200 text-sm font-medium px-6 py-2 rounded-full border border-yellow-200/50 dark:border-yellow-400/20 shadow-sm">
                  Service Details
                </span>
              </div>
              
              <div className="grid grid-cols-1 gap-8">
                {service.quote.item.map((item, id) => (
                  <motion.div
                    key={id}
                    variants={fadeInUp}
                    whileHover={{ scale: 1.03 }}
                    className="bg-white dark:bg-slate-800/50 rounded-3xl p-8 shadow-xl border border-slate-100 dark:border-slate-700 backdrop-blur-lg"
                  >
                    {item.title && (
                      <h2 className="text-3xl font-bold text-slate-900 dark:text-yellow-100 mb-6 text-center">
                        {item.title}
                      </h2>
                    )}
                    
                    {item.desc.map((description, index) => (
                      <div key={index} className="mb-6">
                        <div className="mb-4">
                          <FaQuoteLeft className="text-yellow-500/20 dark:text-yellow-400/20 w-8 h-8 mx-auto" />
                        </div>
                        <p className="text-lg text-slate-600 dark:text-slate-300 leading-relaxed text-center">
                          {description}
                        </p>
                      </div>
                    ))}

                    {item.points.length > 0 && (
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-8">
                        {item.points.map((point, index) => (
                          <div
                            key={index}
                            className="flex items-center space-x-3 bg-yellow-50 dark:bg-yellow-400/10 p-4 rounded-2xl group hover:bg-yellow-100/50 dark:hover:bg-yellow-400/20 transition-colors"
                          >
                            <MdOutlineElectricBolt className="w-6 h-6 text-yellow-600 dark:text-yellow-300 flex-shrink-0 group-hover:text-yellow-700 dark:group-hover:text-yellow-200" />
                            <span className="text-slate-700 dark:text-slate-200 group-hover:text-slate-900 dark:group-hover:text-slate-100">
                              {point}
                            </span>
                          </div>
                        ))}
                      </div>
                    )}
                  </motion.div>
                ))}
              </div>
            </motion.div>
          )}

          {/* Related Services */}
          {relatedServices.length > 0 && (
            <motion.div 
              className="grid grid-cols-1 md:grid-cols-2 gap-8"
              variants={staggerContainer}
              initial="initial"
              animate="animate"
            >
              {relatedServices.map((relatedService) => (
                <Link
                  to={`/service/${relatedService.id}`}
                  key={relatedService.id}
                  className="block group h-full"
                >
                  <div className="bg-white dark:bg-slate-800/50 rounded-3xl shadow-xl overflow-hidden transform backdrop-blur-lg border border-slate-100 dark:border-slate-700 h-full flex flex-col">
                    <div className="relative w-full pt-[56.25%]">
                      <img
                        src={relatedService.icon}
                        alt={relatedService.title}
                        className="absolute top-0 left-0 w-full h-full object-cover"
                        loading="lazy"
                      />
                    </div>
                    <div className="p-8 flex-1 flex flex-col">
                      <h3 className="text-2xl font-bold text-slate-900 dark:text-yellow-100 mb-4 group-hover:text-yellow-600 dark:group-hover:text-yellow-400 transition-colors">
                        {relatedService.title}
                      </h3>
                      <p className="text-slate-600 dark:text-slate-300 line-clamp-3 flex-1">
                        {relatedService.description[0]}
                      </p>
                    </div>
                  </div>
                </Link>
              ))}
            </motion.div>
          )}
        </div>
      </section>
    </>
  );
};

export default Service;
