import TypeWriterEffect from "react-typewriter-effect";
import { TbTargetArrow } from "react-icons/tb";
import { IoTelescopeSharp } from "react-icons/io5";
import { FaQuoteLeft } from "react-icons/fa";
import SEO from "../../../components/SEO";

function About() {
  document.title = `Krico | About`;
  
  return (
    <>
      <SEO 
        title="About KRICO | Leading Electrical Control Panel Manufacturer"
        description="Learn about KRICO's journey as India's premier electrical control panel manufacturer. Discover our mission, vision, and commitment to quality in industrial electrical solutions."
        keywords="about Krico, electrical panel manufacturer history, industrial electrical solutions, company profile, mission vision, quality commitment"
        path="/about"
        ogType="article"
      />
      <section className="py-20 bg-gradient-to-b from-white to-slate-50 dark:from-slate-900 dark:to-slate-800">
        <div className="container mx-auto px-4 max-w-7xl">
          {/* Header */}
          <div className="mb-24 relative" data-aos="fade-up">
            <div className="absolute -top-10 left-0 w-24 h-24 bg-yellow-100 dark:bg-yellow-400/10 rounded-full blur-3xl"></div>
            <div className="mb-4 text-center">
              <span className="bg-yellow-50 dark:bg-yellow-400/10 text-yellow-800 dark:text-yellow-200 text-sm font-medium px-6 py-2 rounded-full border border-yellow-200/50 dark:border-yellow-400/20 shadow-sm">
                Our Story
              </span>
            </div>
            <h1 className="text-6xl font-bold text-slate-900 dark:text-yellow-100 mb-6 tracking-tight text-center">
              About Us
            </h1>
            <p className="text-2xl font-semibold text-slate-600 dark:text-slate-300 max-w-2xl leading-relaxed mx-auto text-center">
              KRICO PANNEL GLOBAL PRIVATE LIMITED
            </p>
          </div>

          {/* Mission & Vision Cards */}
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 mb-24">
            {/* Mission Card */}
            <div 
              className="bg-white dark:bg-slate-800/50 rounded-3xl shadow-xl overflow-hidden transform transition-all hover:scale-[1.02] duration-300 backdrop-blur-lg border border-slate-100 dark:border-slate-700"
              data-aos="fade-right"
            >
              <div className="p-10">
                <div className="flex items-center justify-center mb-10">
                  <div className="bg-yellow-100 dark:bg-yellow-400/10 p-4 rounded-2xl shadow-inner">
                    <TbTargetArrow className="w-10 h-10 text-yellow-600 dark:text-yellow-300" />
                  </div>
                  <h2 className="text-4xl font-bold text-slate-900 dark:text-yellow-100 ml-6">
                    Mission
                  </h2>
                </div>
                <div className="space-y-8 text-slate-600 dark:text-slate-300 text-lg">
                  <TypeWriterEffect
                    startDelay={100}
                    typeSpeed={1}
                    hideCursorAfterText
                    cursorColor="currentColor"
                    text="KRICO PANNEL GLOBAL PRIVATE LIMITED shall be the most trusted, respected, and preferred brand, for Electrical Engineering Solution and Panel Boards Manufacturing that finds application in the Industrial, Commercial, Power Generation, Distribution, and Utilization of Energy Sectors."
                  />
                  <TypeWriterEffect
                    startDelay={100}
                    typeSpeed={1}
                    hideCursorAfterText
                    cursorColor="currentColor"
                    text="In its major businesses Hall not only commands a domestic market and Export Market Share or more but is known widely as the company 'closest to its Industrial Customers'"
                  />
                  <TypeWriterEffect
                    startDelay={100}
                    typeSpeed={1}
                    hideCursorAfterText
                    cursorColor="currentColor"
                    text="KRICO PANNEL GLOBAL PRIVATE LIMITED products shall be used to manage power in worldwide biggest industries, in its Power Sector Companies, and in its most critical infrastructure."
                  />
                </div>
              </div>
            </div>

            {/* Vision Card */}
            <div 
              className="bg-white dark:bg-slate-800/50 rounded-3xl shadow-xl overflow-hidden transform transition-all hover:scale-[1.02] duration-300 backdrop-blur-lg border border-slate-100 dark:border-slate-700"
              data-aos="fade-left"
            >
              <div className="p-10">
                <div className="flex items-center justify-center mb-10">
                  <div className="bg-yellow-100 dark:bg-yellow-400/10 p-4 rounded-2xl shadow-inner">
                    <IoTelescopeSharp className="w-10 h-10 text-yellow-600 dark:text-yellow-300" />
                  </div>
                  <h2 className="text-4xl font-bold text-slate-900 dark:text-yellow-100 ml-6">
                    Vision
                  </h2>
                </div>
                <div className="space-y-8 text-slate-600 dark:text-slate-300 text-lg">
                  <TypeWriterEffect
                    startDelay={100}
                    typeSpeed={1}
                    hideCursorAfterText
                    cursorColor="currentColor"
                    text="To create a unique empower of outstanding products, operational excellence, path-breaking customer service, and compelling marketing"
                  />
                  <TypeWriterEffect
                    startDelay={100}
                    typeSpeed={1}
                    hideCursorAfterText
                    cursorColor="currentColor"
                    text="To create and relish a vibrant workplace where employees are empowered, cared for, developed, and most of all provided unlimited opportunity to discover their full potential."
                  />
                  <TypeWriterEffect
                    startDelay={100}
                    typeSpeed={1}
                    hideCursorAfterText
                    cursorColor="currentColor"
                    text="To continuously enhance our vibrant technologies, and develop new world-class innovation products to expand worldwide."
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Company Values */}
          <div className="mb-24 relative" data-aos="fade-up">
            <div className="absolute -top-10 right-0 w-32 h-32 bg-yellow-100 dark:bg-yellow-400/10 rounded-full blur-3xl"></div>
            <div className="mb-4 text-center">
              <span className="bg-yellow-50 dark:bg-yellow-400/10 text-yellow-800 dark:text-yellow-200 text-sm font-medium px-6 py-2 rounded-full border border-yellow-200/50 dark:border-yellow-400/20 shadow-sm">
                Our Values
              </span>
            </div>
            <h2 className="text-4xl font-bold text-slate-900 dark:text-yellow-100 mb-12 tracking-tight text-center">
              What Drives Us
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {[
                {
                  title: "Excellence",
                  description: "Striving for the highest standards in everything we do"
                },
                {
                  title: "Innovation",
                  description: "Continuously pushing boundaries and embracing new technologies"
                },
                {
                  title: "Customer Focus",
                  description: "Putting our customers at the heart of every decision"
                }
              ].map((value, index) => (
                <div
                  key={index}
                  className="bg-white dark:bg-slate-800/50 p-8 rounded-2xl shadow-lg transform transition-all hover:scale-[1.02] duration-300 backdrop-blur-lg border border-slate-100 dark:border-slate-700"
                  data-aos="fade-up"
                  data-aos-delay={index * 100}
                >
                  <div className="mb-6">
                    <FaQuoteLeft className="text-yellow-500/20 dark:text-yellow-400/20 w-8 h-8" />
                  </div>
                  <h3 className="text-2xl font-bold text-slate-900 dark:text-yellow-100 mb-4 text-center">
                    {value.title}
                  </h3>
                  <p className="text-lg text-slate-600 dark:text-slate-300 leading-relaxed text-center">
                    {value.description}
                  </p>
                </div>
              ))}
            </div>
          </div>

          {/* About Krico */}
          <div className="relative" data-aos="fade-up">
            <div className="absolute -top-10 left-1/2 w-32 h-32 bg-yellow-100 dark:bg-yellow-400/10 rounded-full blur-3xl"></div>
            <div className="mb-4 text-center">
              <span className="bg-yellow-50 dark:bg-yellow-400/10 text-yellow-800 dark:text-yellow-200 text-sm font-medium px-6 py-2 rounded-full border border-yellow-200/50 dark:border-yellow-400/20 shadow-sm">
                About Krico
              </span>
            </div>
            <h2 className="text-4xl font-bold text-slate-900 dark:text-yellow-100 mb-12 tracking-tight text-center">
              Krico Pannel
            </h2>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
              <div className="space-y-6" data-aos="fade-right">
                <p className="text-lg text-slate-600 dark:text-slate-300 leading-relaxed text-center">
                  Krico Pannel is a leading manufacturer of high-quality electrical control panels, specializing in HV (High Voltage), LV (Low Voltage), and MV (Medium Voltage) solutions. With a steadfast commitment to quality, innovation, and customer satisfaction, Krico Pannel has become a trusted partner across industries such as power generation, oil and gas, manufacturing, and infrastructure.
                </p>
                <p className="text-lg text-slate-600 dark:text-slate-300 leading-relaxed text-center">
                  Our expertise lies in designing control panels tailored to meet the precise requirements of our clients, ensuring the safe and efficient operation of electrical systems. From HV panels that manage high-voltage power distribution with cutting-edge features like circuit breakers and protective relays, to LV panels that deliver precise control in commercial and industrial settings, we offer solutions that are built to last. Additionally, our MV panels, engineered for medium voltage applications (11kV to 36kV), provide reliable performance in power plants, substations, and large-scale industrial operations.
                </p>
              </div>
              <div data-aos="fade-left" className="flex items-center">
                <div className="w-full bg-white dark:bg-slate-800/50 rounded-2xl shadow-lg border border-slate-100 dark:border-slate-700 overflow-hidden">
                  <iframe
                    title='Krico'
                    className='w-full aspect-video'
                    loading='lazy'
                    src='https://drive.google.com/file/d/1ZJwIOoMpSwDcOGfAqbUU8qy6rhCCsSQ1/preview'
                    frameBorder='0'
                    allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
            </div>
            <div className="mt-12 space-y-6" data-aos="fade-up">
              <p className="text-lg text-slate-600 dark:text-slate-300 leading-relaxed text-center">
                At Krico Pannel, we prioritize the highest standards of quality and reliability, reflected in our state-of-the-art manufacturing facilities and stringent quality control processes. Our team of skilled engineers works closely with clients to deliver customized solutions, staying at the forefront of technological advancements to offer the most advanced features in every product.
              </p>
              <p className="text-lg text-slate-600 dark:text-slate-300 leading-relaxed text-center">
                With a proven track record of delivering safe, efficient, and innovative control panel solutions, Krico Pannel is committed to empowering industries worldwide.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default About;
