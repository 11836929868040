import { Link, useNavigate } from "react-router-dom";
import { BsMailboxFlag, BsFacebook, BsTwitter, BsInstagram, BsLinkedin, BsYoutube } from "react-icons/bs";
import { SlPhone } from "react-icons/sl";
import { MdOutlineLocationOn } from "react-icons/md";
import { useState } from "react";
import { ScrollToTop } from "../../utils/utils";
import { motion } from "framer-motion";

const LinkBuilder = ({ product }) => {
  return (
    <motion.div 
      className='flex gap-2 group'
      whileHover={{ x: 10 }}
      transition={{ type: "spring", stiffness: 400 }}
    >
      <span className="text-yellow-200 group-hover:text-yellow-400 transition-colors">&#10148;</span>
      <div className='mb-4'>
        <Link
          to={product.link}
          className='hover:text-yellow-200 transition-colors'
          onClick={ScrollToTop}
        >
          {product.text}
        </Link>
      </div>
    </motion.div>
  );
};

export const Address = ({ addres, phone, email, className }) => {
  return (
    <>
      <ContactItem
        icon={<MdOutlineLocationOn className='w-6 h-6 mx-2 text-yellow-500 dark:text-yellow-200' />}
        item={addres}
      />
      <ContactItem
        icon={<SlPhone className='w-6 h-6 mx-2 text-yellow-500 dark:text-yellow-200' />}
        item={phone}
      />
      <ContactItem
        icon={<BsMailboxFlag className='w-6 h-6 mx-2 text-yellow-500 dark:text-yellow-200' />}
        item={email}
      />
    </>
  );
};

const Items = ({ text, clk }) => {
  const [item, setItem] = useState(text);
  return (
    <motion.div 
      className='mb-0.5 cursor-pointer transition-colors' 
      onClick={() => clk(item, setItem)}
      whileHover={{ x: 5 }}
      transition={{ type: "spring", stiffness: 400 }}
    >
      {item}
    </motion.div>
  );
};

const ContactItem = ({ icon, item }) => {
  if (item?.text?.length) {
    return (
      <motion.div 
        className='flex items-start m-2'
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <div className='m-1'>{icon}</div>
        <span className='ml-2 overflow-clip break-words'>
          {item?.text.map((a, i) => (
            <Items text={a} clk={item.clk} key={i} />
          ))}
        </span>
      </motion.div>
    );
  }
};

const Footer = () => {
  const Products = [
    {
      text: "High Voltage Panel",
      link: "/products/hv",
    },
    {
      text: "Medium Voltage Panel",
      link: "/products/mv",
    },
    {
      text: "Low Voltage Panel",
      link: "/products/lv",
    },
  ];

  const Services = [
    {
      text: "Electrical Consultancy",
      link: "/service/consultancy",
    },
    {
      text: "Electrical Engineering Contractors",
      link: "/service/contractors",
    },
    {
      text: "Maintenance",
      link: "/service/maintenance",
    },
  ];

  const SocialLinks = [
    {
      name: "Facebook",
      icon: <BsFacebook className="w-5 h-5" />,
      url: "https://facebook.com/krico",
    },
    {
      name: "Twitter",
      icon: <BsTwitter className="w-5 h-5" />,
      url: "https://twitter.com/krico",
    },
    {
      name: "Instagram",
      icon: <BsInstagram className="w-5 h-5" />,
      url: "https://instagram.com/krico",
    },
    {
      name: "LinkedIn",
      icon: <BsLinkedin className="w-5 h-5" />,
      url: "https://linkedin.com/company/krico",
    },
    {
      name: "YouTube",
      icon: <BsYoutube className="w-5 h-5" />,
      url: "https://youtube.com/krico",
    },
  ];

  const copyContactItems = (text, setText) => {
    navigator.clipboard.writeText(text);
    setText("copied");
    setTimeout(() => {
      setText(text);
    }, 2000);
  };

  const navigate = useNavigate();
  const showMap = () => {
    navigate("/contact");
  };

  return (
    <div className='relative mt-32 bg-gradient-to-b from-slate-900 to-slate-800'>
      <svg
        viewBox='0 0 1440 320'
        preserveAspectRatio='none'
        className='absolute w-full top-0 h-6 -mt-5 sm:-mt-16 sm:h-16 text-slate-900'
      >
        <path
          fill='currentColor'
          d='M0,224L11.4,213.3C22.9,203,46,181,69,154.7C91.4,128,114,96,137,85.3C160,75,183,85,206,106.7C228.6,128,251,160,274,176C297.1,192,320,192,343,165.3C365.7,139,389,85,411,58.7C434.3,32,457,32,480,64C502.9,96,526,160,549,202.7C571.4,245,594,267,617,234.7C640,203,663,117,686,117.3C708.6,117,731,203,754,240C777.1,277,800,267,823,266.7C845.7,267,869,277,891,266.7C914.3,256,937,224,960,224C982.9,224,1006,256,1029,245.3C1051.4,235,1074,181,1097,138.7C1120,96,1143,64,1166,53.3C1188.6,43,1211,53,1234,69.3C1257.1,85,1280,107,1303,122.7C1325.7,139,1349,149,1371,144C1394.3,139,1417,117,1429,106.7L1440,96L1440,320L1428.6,320C1417.1,320,1394,320,1371,320C1348.6,320,1326,320,1303,320C1280,320,1257,320,1234,320C1211.4,320,1189,320,1166,320C1142.9,320,1120,320,1097,320C1074.3,320,1051,320,1029,320C1005.7,320,983,320,960,320C937.1,320,914,320,891,320C868.6,320,846,320,823,320C800,320,777,320,754,320C731.4,320,709,320,686,320C662.9,320,640,320,617,320C594.3,320,571,320,549,320C525.7,320,503,320,480,320C457.1,320,434,320,411,320C388.6,320,366,320,343,320C320,320,297,320,274,320C251.4,320,229,320,206,320C182.9,320,160,320,137,320C114.3,320,91,320,69,320C45.7,320,23,320,11,320L0,320Z'
        ></path>
      </svg>

      <footer className='text-slate-300'>
        <div className='mx-auto w-full max-w-screen-xl'>
          <div className='grid grid-cols-1 gap-8 px-4 py-16 lg:py-8 sm:grid-cols-2 lg:grid-cols-4'>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.1 }}
              className="flex flex-col"
            >
              <h2 className='mb-6 text-sm font-semibold uppercase text-yellow-200'>
                Products
              </h2>
              <div className='font-medium space-y-2'>
                {Products.map((product, index) => (
                  <LinkBuilder product={product} key={index} />
                ))}
              </div>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              className="flex flex-col"
            >
              <h2 className='mb-6 text-sm font-semibold uppercase text-yellow-200'>
                Services
              </h2>
              <div className='font-medium space-y-2'>
                {Services.map((service, index) => (
                  <LinkBuilder product={service} key={index} />
                ))}
              </div>
            </motion.div>
            
            <motion.div 
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.3 }}
              className="flex flex-col"
            >
              <h2 className='mb-6 text-sm font-semibold uppercase text-yellow-200'>
                Saudi Arabia Office
              </h2>
              <div className='font-medium'>
                <Address
                  addres={{
                    clk: showMap,
                    text: [
                      "101, Jubail 31951",
                      "Kingdom of Saudi Arabia",
                    ],
                  }}
                  phone={{
                    clk: copyContactItems,
                    text: [
                      "+966 55 359 9698",
                    ],
                  }}
                  email={{
                    clk: copyContactItems,
                    text: [
                      "sales@kricoelectric.com",
                    ],
                  }}
                />
              </div>
            </motion.div>

            <motion.div 
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.4 }}
              className="flex flex-col"
            >
              <h2 className='mb-6 text-sm font-semibold uppercase text-yellow-200'>
                India Office
              </h2>
              <div className='font-medium'>
                <Address
                  addres={{
                    clk: showMap,
                    text: [
                      "#176, Mettukadu, Chinnamedu,",
                      "Tindal, Erode - 638 012.",
                    ],
                  }}
                  phone={{
                    clk: copyContactItems,
                    text: [
                      "+91 97509 10064",
                    ],
                  }}
                  email={{
                    clk: copyContactItems,
                    text: [
                      "kricoglobalpurchase21@gmail.com",
                    ],
                  }}
                />
              </div>
            </motion.div>
          </div>

          {/* Social Media Links */}
          <div className="px-4 py-8 border-t border-slate-700">
            <motion.div 
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              className="flex flex-col items-center justify-center space-y-4"
            >
              <h2 className="text-sm font-semibold uppercase text-yellow-200">
                Connect With Us
              </h2>
              <div className="flex space-x-6">
                {SocialLinks.map((social, index) => (
                  <motion.a
                    key={social.name}
                    href={social.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-slate-300 hover:text-yellow-200 transition-colors duration-300"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: index * 0.1 }}
                    whileHover={{ scale: 1.2 }}
                    whileTap={{ scale: 0.9 }}
                  >
                    {social.icon}
                    <span className="sr-only">{social.name}</span>
                  </motion.a>
                ))}
              </div>
            </motion.div>
          </div>

          {/* Copyright Section */}
          <div className='px-4 py-6 border-t border-slate-700'>
            <div className='md:flex md:items-center md:justify-between'>
              <span className='text-sm text-slate-300 sm:text-center block w-full md:w-auto'>
                {new Date().getFullYear()}{" "}
                <Link to='/' className='text-yellow-200 hover:text-yellow-400 transition-colors' onClick={ScrollToTop}>
                  KRICO PANNEL
                </Link>
                . All Rights Reserved.
              </span>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
