import { Link, useParams } from "react-router-dom";
import "swiper/css";
import { Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { ScrollToTop } from "../../../utils/utils";
import { Products } from "../productlist/products";
import { motion } from "framer-motion";
import { FaBox } from "react-icons/fa";
import { ProductCard } from "../productlist";
import SEO from "../../../components/SEO";
import { useState } from "react";

const ProductDescription = () => {
  let { pid, currentCategory } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Find category and product with error handling
  const category = Products.find((c) => c.id === currentCategory);
  if (!category) {
    return (
      <section className="py-20 bg-gradient-to-b from-white to-slate-50 dark:from-slate-900 dark:to-slate-800">
        <div className="container mx-auto px-4 max-w-7xl text-center">
          <h1 className="text-4xl font-bold text-slate-900 dark:text-yellow-100">
            Category not found
          </h1>
          <Link 
            to="/products"
            className="inline-block mt-6 px-6 py-2 bg-yellow-50 text-yellow-800 dark:bg-yellow-400/10 dark:text-yellow-200 rounded-full border border-yellow-200/50 dark:border-yellow-400/20 hover:bg-yellow-100 dark:hover:bg-yellow-400/20 transition-colors"
          >
            View All Products
          </Link>
        </div>
      </section>
    );
  }

  const product = category.items.find((p) => p.id.toString() === pid);
  if (!product) {
    return (
      <section className="py-20 bg-gradient-to-b from-white to-slate-50 dark:from-slate-900 dark:to-slate-800">
        <div className="container mx-auto px-4 max-w-7xl text-center">
          <h1 className="text-4xl font-bold text-slate-900 dark:text-yellow-100">
            Product not found
          </h1>
          <Link 
            to={`/products/${currentCategory}`}
            className="inline-block mt-6 px-6 py-2 bg-yellow-50 text-yellow-800 dark:bg-yellow-400/10 dark:text-yellow-200 rounded-full border border-yellow-200/50 dark:border-yellow-400/20 hover:bg-yellow-100 dark:hover:bg-yellow-400/20 transition-colors"
          >
            View Category Products
          </Link>
        </div>
      </section>
    );
  }

  // Prepare meta description and keywords with safety checks
  const metaDescription = product.description && product.description.length > 0
    ? product.description[0].substring(0, 155) + "..."
    : `${product.title} - Industrial electrical control panel product by Krico`;
  
  const keywords = `${product.title}, ${category.title}, electrical control panel, industrial panels, Krico products`;

  // Prepare structured data for the product
  const structuredData = {
    "@context": "https://schema.org/",
    "@type": "Product",
    "name": product.title,
    "description": metaDescription,
    "image": product.img,
    "category": category.title,
    "manufacturer": {
      "@type": "Organization",
      "name": "Krico",
      "url": "https://krico.com"
    }
  };

  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
        delayChildren: 0.3
      }
    }
  };

  const item = {
    hidden: { opacity: 0, y: 20 },
    show: { 
      opacity: 1, 
      y: 0,
      transition: {
        type: "spring",
        bounce: 0.4,
        duration: 0.8
      }
    }
  };

  return (
    <section className="py-20 bg-gradient-to-b from-white to-slate-50 dark:from-slate-900 dark:to-slate-800">
      <SEO 
        title={`Krico | ${product.title}`}
        description={metaDescription}
        keywords={keywords}
        ogType="product"
        ogImage={product.img}
        path={`/product/${currentCategory}/${pid}`}
      />
      <div className="container mx-auto px-4 max-w-7xl">
        {/* Header */}
        <div className="mb-24 relative" data-aos="fade-up">
          <div className="absolute -top-10 left-0 w-24 h-24 bg-yellow-100 dark:bg-yellow-400/10 rounded-full blur-3xl"></div>
          <div className="mb-4 text-center">
            <span className="bg-yellow-50 dark:bg-yellow-400/10 text-yellow-800 dark:text-yellow-200 text-sm font-medium px-6 py-2 rounded-full border border-yellow-200/50 dark:border-yellow-400/20 shadow-sm">
              {category.title}
            </span>
          </div>
          <h1 className="text-6xl font-bold text-slate-900 dark:text-yellow-100 mb-6 tracking-tight text-center">
            {product.title}
          </h1>
        </div>

        {/* Product Details */}
        <motion.div 
          variants={container}
          initial="hidden"
          animate="show"
          className="grid grid-cols-1 lg:grid-cols-2 gap-12 mb-24"
        >
          {/* Product Image */}
          <motion.div
            variants={item}
            className="bg-white dark:bg-slate-800/50 rounded-3xl shadow-xl overflow-hidden transform transition-all hover:scale-[1.02] duration-300 backdrop-blur-lg border border-slate-100 dark:border-slate-700"
          >
            <div className="p-10">
              <img
                className="w-full h-auto rounded-xl"
                src={product.img}
                alt={product.title}
              />
            </div>
          </motion.div>

          {/* Product Description or Specifications */}
          {product.description && product.description.length > 0 ? (
            <motion.div
              variants={item}
              className="bg-white dark:bg-slate-800/50 rounded-3xl shadow-xl overflow-hidden transform transition-all hover:scale-[1.02] duration-300 backdrop-blur-lg border border-slate-100 dark:border-slate-700"
            >
              <div className="p-10">
                <div className="flex items-center justify-center mb-10">
                  <div className="bg-yellow-100 dark:bg-yellow-400/10 p-4 rounded-2xl shadow-inner">
                    <FaBox className="w-10 h-10 text-yellow-600 dark:text-yellow-300" />
                  </div>
                  <h2 className="text-4xl font-bold text-slate-900 dark:text-yellow-100 ml-6">
                    Details
                  </h2>
                </div>
                <div className="space-y-6 text-slate-600 dark:text-slate-300">
                  {product.description.map((description, i) => (
                    <p key={i} className="text-lg leading-relaxed">
                      {description}
                    </p>
                  ))}
                </div>
              </div>
            </motion.div>
          ) : product.spec && product.spec.length > 0 ? (
            <motion.div
              variants={item}
              className="bg-white dark:bg-slate-800/50 rounded-3xl shadow-xl overflow-hidden transform transition-all hover:scale-[1.02] duration-300 backdrop-blur-lg border border-slate-100 dark:border-slate-700"
            >
              <div className="p-10">
                <div className="flex items-center justify-center mb-10">
                  <h2 className="text-4xl font-bold text-slate-900 dark:text-yellow-100">
                    Specifications
                  </h2>
                </div>
                <div className="space-y-4">
                  {product.spec.map((specification, i) => (
                    <motion.div
                      key={i}
                      variants={item}
                      className="p-4 rounded-xl bg-slate-50 dark:bg-slate-800/50 hover:bg-yellow-50 dark:hover:bg-yellow-400/10 transition-colors"
                    >
                      <div className="grid grid-cols-1 md:grid-cols-12 gap-4 items-center">
                        <div className="md:col-span-4 font-semibold text-slate-900 dark:text-yellow-100">
                          {specification.title}
                        </div>
                        <div className="md:col-span-1 text-slate-400 hidden md:block">:</div>
                        <div className="md:col-span-7 text-slate-600 dark:text-slate-300">
                          {specification.value}
                        </div>
                      </div>
                    </motion.div>
                  ))}
                </div>
              </div>
            </motion.div>
          ) : null}
        </motion.div>

        {/* Specifications - Only show if we have both details and specifications */}
        {product.description && product.description.length > 0 && product.spec && product.spec.length > 0 && (
          <motion.div
            variants={container}
            initial="hidden"
            animate="show"
            className="mb-24"
          >
            <motion.div
              variants={item}
              className="bg-white dark:bg-slate-800/50 rounded-3xl shadow-xl overflow-hidden transform transition-all backdrop-blur-lg border border-slate-100 dark:border-slate-700"
            >
              <div className="p-10">
                <div className="flex items-center justify-center mb-10">
                  <h2 className="text-4xl font-bold text-slate-900 dark:text-yellow-100">
                    Specifications
                  </h2>
                </div>
                <div className="space-y-4">
                  {product.spec.map((specification, i) => (
                    <motion.div
                      key={i}
                      variants={item}
                      className="p-4 rounded-xl bg-slate-50 dark:bg-slate-800/50 hover:bg-yellow-50 dark:hover:bg-yellow-400/10 transition-colors"
                    >
                      <div className="grid grid-cols-1 md:grid-cols-12 gap-4 items-center">
                        <div className="md:col-span-4 font-semibold text-slate-900 dark:text-yellow-100">
                          {specification.title}
                        </div>
                        <div className="md:col-span-1 text-slate-400 hidden md:block">:</div>
                        <div className="md:col-span-7 text-slate-600 dark:text-slate-300">
                          {specification.value}
                        </div>
                      </div>
                    </motion.div>
                  ))}
                </div>
              </div>
            </motion.div>
          </motion.div>
        )}

        {/* Related Products */}
        <motion.div
          variants={container}
          initial="hidden"
          animate="show"
          className="mb-24"
        >
          <div className="mb-10 text-center">
            <span className="bg-yellow-50 dark:bg-yellow-400/10 text-yellow-800 dark:text-yellow-200 text-sm font-medium px-6 py-2 rounded-full border border-yellow-200/50 dark:border-yellow-400/20 shadow-sm">
              Related Products
            </span>
          </div>
          <Swiper
            modules={[Autoplay]}
            autoplay={{
              delay: 10000,
              stopOnLastSlide: false,
              pauseOnMouseEnter: true,
            }}
            breakpoints={{
              680: {
                slidesPerView: 2,
                spaceBetween: 24,
              },
              1500: {
                slidesPerView: 3,
                spaceBetween: 24,
              },
            }}
            loop={true}
            className="pb-12"
          >
            {category.items
              .filter(item => item.id.toString() !== pid)
              .map((item, index) => (
                <SwiperSlide key={index}>
                  <ProductCard 
                    product={item} 
                    link={`product/${currentCategory}`}
                  />
                </SwiperSlide>
              ))}
          </Swiper>
        </motion.div>
      </div>
    </section>
  );
};

export default ProductDescription;
