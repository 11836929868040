import { Link, useParams } from "react-router-dom";
import { Products } from "./products";
import { Projects } from "./projects";
import { ScrollToTop } from "../../../utils/utils";
import { motion, AnimatePresence } from "framer-motion";
import SEO from "../../../components/SEO";

const ProductList = () => {
  let { page, currentCategory } = useParams();

  let pageInfo;
  let link;

  // Generate SEO data based on page type and category
  const getSeoData = () => {
    // Default SEO data
    let seoData = {
      title: "Products & Projects | KRICO - Industrial Electrical Solutions",
      description: "Explore KRICO's comprehensive range of industrial electrical control panels and successful project implementations.",
      keywords: "electrical control panels, industrial solutions, power distribution",
      path: `/${page}${currentCategory ? `/${currentCategory}` : ''}`,
      ogType: "website"
    };

    if (page === "projects") {
      const projectKeywords = "industrial projects, electrical installations, control panel projects, successful implementations";
      seoData = {
        title: "Projects Portfolio | KRICO - Industrial Electrical Solutions",
        description: "Discover KRICO's diverse portfolio of successful industrial electrical projects across various sectors including textile, pharmaceutical, and chemical industries.",
        keywords: `${projectKeywords}, project portfolio, industry solutions`,
        path: "/projects",
        ogType: "article",
        ogTitle: "Industrial Electrical Projects Portfolio | KRICO",
        ogDescription: "View our successful implementations of electrical control panels across different industries.",
        structuredData: {
          "@context": "https://schema.org",
          "@type": "CollectionPage",
          "name": "KRICO Industrial Projects",
          "description": "Portfolio of industrial electrical control panel implementations",
          "provider": {
            "@type": "Organization",
            "name": "KRICO"
          }
        }
      };
    } else if (page === "products" && currentCategory) {
      // Category-specific SEO data
      const categoryData = {
        "hv": {
          title: "High Voltage Panels | KRICO",
          description: "Premium High Voltage electrical control panels by KRICO. Advanced solutions for reliable power distribution including Load Break Switches and VCBs.",
          keywords: "high voltage panels, HV electrical panels, load break switch, vacuum circuit breaker",
          voltageRange: "Above 33kV"
        },
        "mv": {
          title: "Medium Voltage Panels | KRICO",
          description: "Advanced Medium Voltage control panels engineered by KRICO. Comprehensive solutions for efficient power management.",
          keywords: "medium voltage panels, MV electrical panels, air circuit breaker, motor control center",
          voltageRange: "3.3kV to 33kV"
        },
        "lv": {
          title: "Low Voltage Panels | KRICO",
          description: "Efficient Low Voltage control panels manufactured by KRICO. Complete range of solutions for precise power control.",
          keywords: "low voltage panels, LV electrical panels, LTCT meter box, distribution board",
          voltageRange: "Up to 3.3kV"
        }
      };

      const category = categoryData[currentCategory];
      if (category) {
        seoData = {
          title: category.title,
          description: category.description,
          keywords: `${category.keywords}, industrial electrical solutions, power control panels`,
          path: `/products/${currentCategory}`,
          ogType: "product",
          ogTitle: `${category.title} - Industrial Solutions`,
          ogDescription: category.description,
          structuredData: {
            "@context": "https://schema.org",
            "@type": "Product",
            "category": `${currentCategory.toUpperCase()} Electrical Panels`,
            "name": category.title,
            "description": category.description,
            "manufacturer": {
              "@type": "Organization",
              "name": "KRICO"
            },
            "additionalProperty": {
              "@type": "PropertyValue",
              "name": "Voltage Range",
              "value": category.voltageRange
            }
          }
        };
      }
    }

    return seoData;
  };

  if (page === "projects") {
    pageInfo = Projects;
    link = "projects";
    document.title = `Krico | Projects`;
  } else if (page === "products") {
    pageInfo = Products.find((p) => p.id === currentCategory);
    link = `product/${currentCategory}`;
    document.title = `Krico | ${pageInfo?.title}`;
  }

  const seoData = getSeoData();

  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
        delayChildren: 0.3
      }
    }
  };

  const item = {
    hidden: { opacity: 0, y: 20 },
    show: { 
      opacity: 1, 
      y: 0,
      transition: {
        type: "spring",
        bounce: 0.4,
        duration: 0.8
      }
    }
  };

  const fadeInUp = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.6,
        ease: "easeOut"
      }
    }
  };

  return (
    <>
      <SEO 
        title={seoData.title}
        description={seoData.description}
        keywords={seoData.keywords}
        path={seoData.path}
        ogType={seoData.ogType}
        ogTitle={seoData.ogTitle}
        ogDescription={seoData.ogDescription}
        structuredData={seoData.structuredData}
      />
      <section className="py-20 bg-gradient-to-b from-white to-slate-50 dark:from-slate-900 dark:to-slate-800">
        <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
          className='container mx-auto px-4 max-w-7xl'
        >
          <div className="mb-24 relative" data-aos="fade-up">
            <motion.div 
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              className="absolute -top-10 left-0 w-24 h-24 bg-yellow-100 dark:bg-yellow-400/10 rounded-full blur-3xl"
            />
            <motion.div 
              variants={fadeInUp}
              initial="hidden"
              animate="visible"
              className="mb-4 text-center"
            >
              <span className="bg-yellow-50 dark:bg-yellow-400/10 text-yellow-800 dark:text-yellow-200 text-sm font-medium px-6 py-2 rounded-full border border-yellow-200/50 dark:border-yellow-400/20 shadow-sm">
                {page === "projects" ? "Our Projects" : "Our Products"}
              </span>
            </motion.div>
            <motion.h1 
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.3 }}
              className="text-6xl font-bold text-slate-900 dark:text-yellow-100 mb-6 tracking-tight text-center"
            >
              {pageInfo?.title}
            </motion.h1>
            <motion.h2 
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.4 }}
              className="text-2xl font-semibold text-slate-600 dark:text-slate-300 max-w-2xl leading-relaxed mx-auto text-center"
            >
              {pageInfo?.range}
            </motion.h2>
            <motion.div 
              variants={fadeInUp}
              initial="hidden"
              animate="visible"
              transition={{ delay: 0.5 }}
              className="mt-12 space-y-6 text-slate-600 dark:text-slate-300 text-lg max-w-4xl mx-auto"
            >
              {pageInfo?.description?.map((description, i) => (
                <motion.p
                  key={i}
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.5, delay: 0.6 + (i * 0.1) }}
                  className="leading-relaxed tracking-wide first-letter:text-3xl first-letter:font-bold first-letter:text-yellow-600 dark:first-letter:text-yellow-300"
                >
                  {description}
                </motion.p>
              ))}
            </motion.div>
          </div>

          <motion.div 
            variants={container}
            initial="hidden"
            animate="show"
            className='grid grid-cols-1 gap-8 md:grid-cols-2 xl:grid-cols-3 mt-12'
          >
            <AnimatePresence>
              {pageInfo?.items?.map((product, i) => (
                <motion.div 
                  key={i} 
                  variants={item}
                  data-aos="zoom-in"
                  data-aos-delay={i * 100}
                >
                  <ProductCard product={product} link={link} />
                </motion.div>
              ))}
            </AnimatePresence>
          </motion.div>
        </motion.div>
      </section>
    </>
  );
};

const ProductCard = ({ product, link }) => {
  return (
    <Link
      to={`/${link}/${product.id}`}
      onClick={ScrollToTop}
      className="group bg-white dark:bg-slate-800/50 rounded-3xl shadow-xl overflow-hidden transform transition-all hover:scale-[1.02] duration-300 backdrop-blur-lg border border-slate-100 dark:border-slate-700 p-8 flex flex-col items-center"
    >
      <motion.div 
        whileHover={{ scale: 1.05 }}
        transition={{ type: "spring", stiffness: 300 }}
        className='relative w-64 h-64 rounded-2xl overflow-hidden shadow-lg'
      >
        <img
          src={product.img[0]}
          alt={product.title}
          className='w-full h-full object-cover transition-transform duration-500 group-hover:scale-110'
        />
      </motion.div>
      
      <div className='mt-8 text-center'>
        <h3 className='text-2xl font-bold text-slate-900 dark:text-yellow-100 mb-4 transition-colors duration-300 group-hover:text-yellow-600 dark:group-hover:text-yellow-300'>
          {product.title}
        </h3>
      </div>
      
      <motion.div 
        className='mt-auto'
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
      >
        <span className='inline-flex items-center px-6 py-2.5 rounded-full bg-yellow-100 dark:bg-yellow-400/10 text-yellow-800 dark:text-yellow-200 font-medium transform transition-all duration-300 hover:shadow-lg group-hover:bg-yellow-200 dark:group-hover:bg-yellow-400/20 border border-yellow-200/50 dark:border-yellow-400/20'>
          View Details
        </span>
      </motion.div>
    </Link>
  );
};

export default ProductList;
export { ProductCard };