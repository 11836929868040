import { motion, AnimatePresence } from "framer-motion";
import { FaIndustry, FaCogs } from "react-icons/fa";
import { useState } from "react";
import SEO from "../../../components/SEO";

const Infrastructure = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedTitle, setSelectedTitle] = useState(null);

  const infrastructure = {
    title: "Infrastructure",
    items: [
      {
        title: "Corporate Office, Designing Area",
        img: [
          "https://ionvidle.sirv.com/krico/infrastructure/Corporate%20office%2C%20Designing%20Area.jpg",
        ],
      },
      {
        title: "MIG & ARC Welding Unit",
        img: [
          "https://ionvidle.sirv.com/krico/infrastructure/MIG%20%26%20ARC%20Welding%20Unit.jpg",
        ],
      },
      {
        title: "Laser Cutting Machine",
        img: [
          "https://ionvidle.sirv.com/krico/infrastructure/Laser%20cutting%20machine.jpg",
        ],
      },
      {
        title: "CNC PRESS BRAKE MACHINE – 80 T / 4000 mm",
        img: [
          "https://ionvidle.sirv.com/krico/infrastructure/bending-machine.jpg",
        ],
      },
      {
        title: "CNC SHEARING MACHINE – 4 x 3200 mm",
        img: [
          "https://ionvidle.sirv.com/krico/infrastructure/cutting-machine.jpg",
        ],
      },
      {
        title: "POWDER COATING UNIT",
        img: [
          "https://ionvidle.sirv.com/krico/infrastructure/powder-coating.jpg",
        ],
      },
      {
        title: "8 Tank Process",
        img: [
          "https://ionvidle.sirv.com/krico/infrastructure/8%20tank%20process.jpeg",
        ],
      },
      {
        title: "Busbar Bending Machine",
        img: [
          "https://ionvidle.sirv.com/krico/infrastructure/BUSBAR%20%20BENDING%20MACHINE.jpeg",
        ],
      },
    ],
  };

  document.title = `Krico | Infrastructure`;

  const fadeInUp = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.6,
        ease: "easeOut"
      }
    }
  };

  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
        delayChildren: 0.3
      }
    }
  };

  const imageVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    show: { 
      opacity: 1, 
      scale: 1,
      transition: {
        type: "spring",
        stiffness: 260,
        damping: 20
      }
    },
    hover: { 
      scale: 1.05,
      transition: {
        type: "spring",
        stiffness: 400,
        damping: 10
      }
    },
    tap: { scale: 0.95 }
  };

  const overlayVariants = {
    hidden: { opacity: 0 },
    visible: { 
      opacity: 1,
      transition: {
        duration: 0.2
      }
    },
    exit: {
      opacity: 0,
      transition: {
        duration: 0.2
      }
    }
  };

  const modalVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 25
      }
    },
    exit: {
      opacity: 0,
      scale: 0.8,
      transition: {
        duration: 0.2
      }
    }
  };

  return (
    <>
      <SEO 
        title="Infrastructure | KRICO - State-of-the-Art Manufacturing Facilities"
        description="Explore KRICO's advanced manufacturing infrastructure for electrical control panels. Our state-of-the-art facilities ensure precision engineering and quality production of industrial electrical solutions."
        keywords="manufacturing facility, electrical panel production, industrial infrastructure, quality control facility, engineering workspace, production unit"
        path="/infrastructure"
        ogType="article"
      />
      <section className="py-20 bg-gradient-to-b from-white to-slate-50 dark:from-slate-900 dark:to-slate-800">
        <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
          className="container mx-auto px-4 max-w-7xl"
        >
          <div className="mb-24 relative" data-aos="fade-up">
            <motion.div 
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              className="absolute -top-10 left-0 w-24 h-24 bg-yellow-100 dark:bg-yellow-400/10 rounded-full blur-3xl"
            />
            
            <motion.div 
              variants={fadeInUp}
              initial="hidden"
              animate="visible"
              className="mb-4 text-center"
            >
              <span className="bg-yellow-50 dark:bg-yellow-400/10 text-yellow-800 dark:text-yellow-200 text-sm font-medium px-6 py-2 rounded-full border border-yellow-200/50 dark:border-yellow-400/20 shadow-sm">
                Our Facilities
              </span>
            </motion.div>

            <motion.h1 
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.3 }}
              className="text-6xl font-bold text-slate-900 dark:text-yellow-100 mb-6 tracking-tight text-center"
            >
              {infrastructure.title}
            </motion.h1>

            <motion.div 
              variants={container}
              initial="hidden"
              animate="show"
              className="mt-12"
            >
              <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                {infrastructure.items.map((item, index) => (
                  <motion.div
                    key={index}
                    variants={imageVariants}
                    initial="hidden"
                    animate="show"
                    whileHover="hover"
                    whileTap="tap"
                    onClick={() => {
                      setSelectedImage(item.img[0]);
                      setSelectedTitle(item.title);
                    }}
                    className="relative aspect-video rounded-2xl overflow-hidden shadow-xl cursor-pointer transform-gpu group"
                    data-aos="zoom-in"
                    data-aos-delay={index * 100}
                  >
                    <motion.img
                      src={item.img[0]}
                      alt={item.title}
                      className="w-full h-full object-cover"
                      layoutId={`infrastructure-image-${index}`}
                    />
                    <motion.div 
                      className="absolute inset-0 bg-gradient-to-t from-black/80 via-black/40 to-transparent opacity-100 flex flex-col items-center justify-end p-6"
                    >
                      <motion.div
                        initial={{ y: 20, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{ delay: 0.1 }}
                        className="text-center"
                      >
                        <FaCogs className="text-3xl text-yellow-300 mb-3 mx-auto" />
                        <h3 className="text-xl font-bold text-white mb-2">
                          {item.title}
                        </h3>
                        <span className="inline-flex items-center space-x-2 bg-yellow-400/20 backdrop-blur-sm px-4 py-2 rounded-full">
                          <FaIndustry className="text-yellow-300" />
                          <span className="text-yellow-200 text-sm">View Details</span>
                        </span>
                      </motion.div>
                    </motion.div>
                  </motion.div>
                ))}
              </div>
            </motion.div>
          </div>
        </motion.div>

        {/* Image Modal */}
        <AnimatePresence>
          {selectedImage && (
            <motion.div
              variants={overlayVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
              onClick={() => {
                setSelectedImage(null);
                setSelectedTitle(null);
              }}
              className="fixed inset-0 bg-black/80 z-50 flex items-center justify-center p-4"
            >
              <motion.div
                variants={modalVariants}
                initial="hidden"
                animate="visible"
                exit="exit"
                className="relative max-w-5xl w-full bg-white dark:bg-slate-800 rounded-3xl overflow-hidden shadow-2xl"
                onClick={(e) => e.stopPropagation()}
              >
                <img
                  src={selectedImage}
                  alt={selectedTitle}
                  className="w-full h-full object-contain"
                />
                <div className="absolute bottom-0 inset-x-0 bg-gradient-to-t from-black/80 p-6">
                  <h3 className="text-2xl font-bold text-white text-center">
                    {selectedTitle}
                  </h3>
                </div>
                <motion.button
                  className="absolute top-4 right-4 text-white bg-black/50 rounded-full p-2 hover:bg-black/70 transition-colors"
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                  onClick={() => {
                    setSelectedImage(null);
                    setSelectedTitle(null);
                  }}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </motion.button>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>
      </section>
    </>
  );
};

export default Infrastructure;
