import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay } from "swiper/modules";

function Clients() {
  const img = [
    "https://ionvidle.sirv.com/krico/client/1683821521584.png",
    "https://ionvidle.sirv.com/krico/client/Ansell-Primary-Corporate-Logo-Blue-16-9.webp",
    "https://ionvidle.sirv.com/krico/client/britannia_industries1.png",
    "https://ionvidle.sirv.com/krico/client/images.png",
    "https://ionvidle.sirv.com/krico/client/logo.png",
    "https://ionvidle.sirv.com/krico/client/logo%20(2).png",
    "https://ionvidle.sirv.com/krico/client/logo_new-2.png",
    "https://ionvidle.sirv.com/krico/client/logo%20(1).png",
    "https://ionvidle.sirv.com/krico/client/Marico_Logo.svg.png",
    "https://ionvidle.sirv.com/krico/client/maxresdefault.png",
    "https://ionvidle.sirv.com/krico/client/MilkyMist_Dairy.png",
    "https://ionvidle.sirv.com/krico/client/Payal-Plastichem-New.png",
    "https://ionvidle.sirv.com/krico/client/pidilite.png",
    "https://ionvidle.sirv.com/krico/client/TANGEDCOpng",
  ];

  return (
    <section className="relative py-20 bg-white dark:bg-slate-900">
      <div className="container relative mx-auto px-4">
        {/* Header Section */}
        <div className="text-center mb-16" data-aos="fade-up">
          <div className="inline-block mb-4">
            <span className="bg-amber-50 dark:bg-amber-400/10 text-amber-800 dark:text-amber-200 text-sm font-medium px-4 py-1.5 rounded-full border border-amber-200/50 dark:border-amber-400/20">
              Our Partners
            </span>
          </div>
          <h2 className="text-4xl font-bold text-slate-900 dark:text-amber-100 mb-4">
            Our Trusted Clients
          </h2>
          <p className="text-slate-600 dark:text-slate-300 max-w-2xl mx-auto">
            Partnering with industry leaders to deliver exceptional electrical engineering solutions across diverse sectors
          </p>
        </div>

        {/* Simple divider */}
        <div className="relative mb-16">
          <div className="absolute left-1/2 -translate-x-1/2 -top-2 w-24 h-0.5 bg-amber-400/50 dark:bg-amber-400/20"></div>
        </div>

        {/* Clients Slider */}
        <div data-aos="fade-up" data-aos-delay="100">
          <Swiper
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
            centeredSlides={true}
            modules={[Autoplay]}
            slidesPerView={1}
            loop={true}
            breakpoints={{
              640: {
                slidesPerView: 2,
              },
              1024: {
                slidesPerView: 3,
              },
              1536: {
                slidesPerView: 5,
              },
            }}
            className="w-full h-60"
          >
            {img.map((ele) => (
              <SwiperSlide key={ele}>
                <Client img={ele} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>

        {/* Bottom Caption */}
        <div className="text-center mt-12" data-aos="fade-up" data-aos-delay="200">
          <div className="inline-block bg-amber-50 dark:bg-amber-400/10 px-6 py-3 rounded-full border border-amber-200/50 dark:border-amber-400/20">
            <p className="text-sm font-medium text-slate-900 dark:text-amber-200">
              Join our growing list of satisfied clients
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

const Client = ({ img }) => {
  return (
    <div className="group bg-yellow-300/90 dark:bg-yellow-300/90 flex-shrink-0 m-auto relative overflow-hidden max-w-xs w-60 h-40 lg:w-48 border border-yellow-400 dark:border-yellow-400 flex justify-center items-center transition-all duration-300 hover:scale-105 hover:bg-yellow-400/95 dark:hover:bg-yellow-400/95 hover:shadow-lg hover:shadow-yellow-300/40">
      <div className="relative w-40 h-24 flex items-center justify-center">
        <img 
          className="max-w-full max-h-full object-contain brightness-100 contrast-105 transition-all duration-300 group-hover:scale-110" 
          src={img} 
          alt="" 
          loading="lazy"
        />
      </div>
    </div>
  );
};

export default Clients;
